import Vue from 'vue';
import VueI18n, { LocaleMessages, NumberFormats, DateTimeFormats } from 'vue-i18n';

Vue.use(VueI18n);

function loadLocales () {
  const requireLocale = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  const messages: LocaleMessages = {};
  const numberFormats: NumberFormats = {};
  const dateTimeFormats: DateTimeFormats = {};

  requireLocale.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];

      messages[locale] = requireLocale(key).messages;
      numberFormats[locale] = requireLocale(key).numberFormats;
      dateTimeFormats[locale] = requireLocale(key).dateTimeFormats;
    }
  });

  return { messages, numberFormats, dateTimeFormats };
}

export default new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  ...loadLocales(),
});
