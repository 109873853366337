import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  ArtikelId: UUID;
  Stichtag: string;
  FilialeId: UUID;
}

export interface Response {
  ArtikelverbundbestandteilInformationDatas: ArtikelverbundbestandteilInformation[];
}

export interface ArtikelverbundbestandteilInformation {
  ArtikelId: UUID;
  Bezeichnung: string;
  Artikelnummer: number;
  Hersteller: string;
  AnzahlTeile: number;
  Inhalt: string;
  Gtin: number;
  IstAktiv: boolean;
  Sortiment: string;
}

export async function artikelverbundbestandteilinformationLaden(
  request: Request,
): Promise<Response> {
  return client.request('art', 'ArtikelverbundbestandteilinformationLaden', request)
    .then(response => response.data);
}
