<template>
  <b-popover
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $scopedSlots"
      #[slot]="scope"
    >
      <slot
        v-bind="scope"
        :name="slot"
      />
    </template>
  </b-popover>
</template>

<script>
import { BPopover } from 'bootstrap-vue';

export default {
  name: 'VPopover',

  components: {
    BPopover,
  },
};
</script>
