import { Module } from 'vuex';
import { State as RootState } from '.';

import attributeService, { AttributeGroup } from '@/attributes';
import { formatIso } from '@/utils/date';

import {
  artikelpassLaden,
  bezuegeEinesArtikelsLaden,
  gtinsEinerVerpackungLaden,
  preishistorieLaden,
  spezialattributeLaden,
  spezialattributeEinesArtikelsLaden,
  artikelverbundLaden,
  artikelverbundbestandteilinformationLaden,
  artikelAendernValidieren,
} from '@/api/bitwws';
import { Artikelpass, ArtikelArt } from '@/api/bitwws/artikelpassLaden';
import { Informationsart } from '@/api/bitwws/enums/Informationsart';
import { BezugsInformation } from '@/api/bitwws/bezuegeEinesArtikelsLaden';
import { GtinData } from '@/api/bitwws/gtinsEinerVerpackungLaden';
import { AktuellePreisdaten, PreishistorieEntry } from '@/api/bitwws/preishistorieLaden';
import { Response as ArtikelverbundLadenResponse } from '@/api/bitwws/artikelverbundLaden';
import { Response as ArtikelverbundbestandteilinformationLadenResponse } from '@/api/bitwws/artikelverbundbestandteilinformationLaden';
import { Response as ArtikelAendernValidierenResponse } from '@/api/bitwws/artikelAendernValidieren';

export interface Article {
  id: string;
  number: number;
  setNumber: number;
  gtin: string;
  pluCode: number | null;
  pluCodeRange: number | null;
  name: string;
  supplier: string | null;
  manufacturer: string;
  brand: string | null;
  assortment: string;
  assortmentNumber: string;
  mainPlacement: string;
  mainPlacementNumber: string;
  productGroup: string;
  productGroupNumber: string;
  productGroupId: string;
  salesUnitType: string;
  packagingType: string;
  packagingDescription: string | null;
  packageId: string;
  contents: {
    factor: number;
    quantity: number;
    unit: string;
  };
  dimensions: {
    height: number;
    width: number;
    depth: number;
  };
  weight: number;
  vatType: string;
  vatTypeValue: number;
  createdAt: string;
  isEqualized: boolean;
  isSeasonalSpecialItem: boolean;
  isPseudo: boolean;
  isControllable: boolean;
  isDecentralized: boolean;
  shelfText: string;
  receiptText: string;
  priceBasisQuantity: number | null;
  priceBasisQuantityUnit: string | null;
  priceComparisonMeasurement: number | null;
  priceComparisonMeasurementUnit: string | null;
  retailPrice: number | null;
  productInfos: string | null;
  ingredientList: string | null;
  listingReason: string | null;
  discountable: boolean | null;
  priceEntryRequired: boolean;
  deposit: {
    name: string;
    articleNumber: number;
    value: number;
    type: string;
  } | null;
  isCompound: boolean;
  isComponent: boolean;
  listed: boolean;
  hasDecentralizedPurchaseUnit: boolean;
  compoundType: string;
}

export interface Package {
  id: string;
  version: number;
  gtin: string;
  name: string | null;
  quantity: number;
  height: number;
  width: number;
  depth: number;
  weight: number;
  purchaseUnits: PurchaseUnit[];
  editable: boolean;
}

export interface PurchaseUnit {
  id: string;
  version: number;
  supplier: string;
  supplierItemNumber: string | null;
  centralArticleNumber: string;
  editable: boolean;
}

export interface Gtin {
  id: string;
  gtin: string;
  main: boolean;
  inHouse: boolean;
  validityNote: string | null;
}

function findProductInfo(data: Artikelpass, type: Informationsart) {
  return data.Artikelinformationen.find(i => i.Informationsart === type)?.Information ?? null;
}

function mapArticleData(data: Artikelpass): Article {
  return {
    id: data.ArtikelId,
    number: data.Artikelnummer,
    setNumber: data.Setnummer,
    gtin: data.Gtin,
    pluCode: data.Plu,
    pluCodeRange: data.PluKreis,
    name: data.ArtikelBezeichnung,
    supplier: data.Lieferantenbezeichnung,
    manufacturer: data.Hersteller,
    brand: data.Marke,
    assortment: data.Sortiment,
    assortmentNumber: data.SortimentNummer,
    mainPlacement: data.Hauptplatzierung,
    mainPlacementNumber: data.HauptplatzierungNummer,
    productGroup: data.Warengruppe,
    productGroupNumber: data.WarengruppeNummer,
    productGroupId: data.WarengruppeId,
    salesUnitType: data.ArtDerVerkaufseinheit,
    packagingType: data.Verpackungsart,
    packagingDescription: data.VerpackungBezeichnung,
    packageId: data.VerpackungId,
    contents: {
      factor: data.InhaltFaktor,
      quantity: data.InhaltMenge,
      unit: data.InhaltEinheit,
    },
    dimensions: {
      height: data.Hoehe,
      width: data.Breite,
      depth: data.Tiefe,
    },
    weight: data.Gewicht,
    vatType: data.Umsatzsteuer,
    vatTypeValue: data.UmsatzsteuerWert,
    createdAt: data.Anlagedatum,
    isEqualized: data.IstEgalisiert,
    isSeasonalSpecialItem: data.IstSonderpostenSaison,
    isPseudo: data.IstPseudoArtikel,
    isControllable: data.IstSteuerbar,
    isDecentralized: [ArtikelArt.DEZENTRAL, ArtikelArt.DEZENTRAL_SEH].includes(data.ArtikelArt),
    shelfText: data.Regaltext,
    receiptText: data.Bontext,
    priceBasisQuantity: data.Basismenge,
    priceBasisQuantityUnit: data.BasismengeEinheit,
    priceComparisonMeasurement: data.RelevanteFuellmenge,
    priceComparisonMeasurementUnit: data.RelevanteFuellmengeEinheit,
    retailPrice: null,
    productInfos: findProductInfo(data, Informationsart.Produktinfo),
    ingredientList: findProductInfo(data, Informationsart.Zutatentext),
    listingReason: findProductInfo(data, Informationsart.Listungsgrund),
    discountable: data.IstRabattfaehig,
    priceEntryRequired: data.PreiseingabeAnKasse,
    deposit: data.Leergutzuordnung ? {
      articleNumber: data.Leergutzuordnung.Artikelnummer,
      name: data.Leergutzuordnung.Artikelbezeichnung,
      type: data.Leergutzuordnung.LeergutKreislauf,
      value: data.Leergutzuordnung.Pfandwert,
    } : null,
    isCompound: data.IstVerbundartikel,
    isComponent: data.IstVerbundbestandteil,
    listed: data.IstGelistet,
    hasDecentralizedPurchaseUnit: data.HatDezentralenBezug,
    compoundType: data.ArtikelverbundArt,
  };
}

function mapPackageData(data: BezugsInformation): Package {
  return {
    id: data.VerpackungId,
    version: data.VerpackungRowVersion,
    gtin: data.Gtin,
    name: data.Bezeichnung,
    quantity: data.MengeArtikelInVerpackung,
    height: data.Hoehe,
    width: data.Breite,
    depth: data.Tiefe,
    weight: data.Gewicht,
    editable: data.KundeDarfVerpackungAendern,
    purchaseUnits: data.Bezugseinheiten.map((data) => ({
      id: data.Id,
      version: data.BezugseinheitRowVersion,
      supplier: data.Lieferant,
      supplierItemNumber: data.Lieferantenartikelnummer,
      centralArticleNumber: data.Zan,
      editable: data.KundeDarfBezugAendern,
    })),
  };
}

function mapGtinData(data: GtinData): Gtin {
  return {
    id: data.Id,
    gtin: data.Gtin,
    main: data.IstHauptGtin,
    inHouse: data.IstHausintern,
    validityNote: data.GueltigkeitHinweis,
  };
}

interface State {
  currentArticle: Article | null;
  attributes: AttributeGroup[] | null;
  packages: Package[];
  gtins: Gtin[];
  gtinsPackageVersion: number | null;
  prices: AktuellePreisdaten | null;
  priceHistory: PreishistorieEntry[];
  componentData: ArtikelverbundLadenResponse | null;
  compoundData: ArtikelverbundbestandteilinformationLadenResponse | null;
  articleEditMeta: ArtikelAendernValidierenResponse | null;
}

const storeModule: Module<State, RootState> = {
  namespaced: true,

  state: {
    currentArticle: null,
    attributes: null,
    packages: [],
    gtins: [],
    gtinsPackageVersion: null,
    prices: null,
    priceHistory: [],
    componentData: null,
    compoundData: null,
    articleEditMeta: null,
  },

  getters: {
    canEditNonSpecificData(state) {
      return state.articleEditMeta?.DarfKundeMarktunabhaengigeArtikeldatenAendern ?? false;
    },
  },

  mutations: {
    setCurrentArticle(state, article) {
      state.currentArticle = article;
    },

    setAttributes(state, attributes) {
      state.attributes = attributes;
    },

    setPackages(state, packages) {
      state.packages = packages;
    },

    setGtins(state, gtins) {
      state.gtins = gtins;
    },

    setGtinsPackageVersion(state, gtinsPackageVersion) {
      state.gtinsPackageVersion = gtinsPackageVersion;
    },

    setPrices(state, prices) {
      state.prices = prices;
    },

    setPriceHistory(state, priceHistory) {
      state.priceHistory = priceHistory;
    },

    setComponentData(state, componentData) {
      state.componentData = componentData;
    },

    setCompoundData(state, compoundData) {
      state.compoundData = compoundData;
    },

    setArticleEditMeta(state, articleEditMeta) {
      state.articleEditMeta = articleEditMeta;
    },
  },

  actions: {
    fetchArticle({ commit, rootState, rootGetters }, id) {
      return artikelpassLaden({
        ArtikelId: id,
        Stichtag: formatIso(rootState.currentDate),
        FilialId: rootGetters.currentStoreId,
      })
        .then((response) => {
          commit('setCurrentArticle', mapArticleData(response.Artikelpass));
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            commit('setArticleBasic', null);
          }
        });
    },

    fetchAttributes({ state, commit }) {
      const article = state.currentArticle;

      if (article === null) {
        commit('setAttributes', null);

        return Promise.resolve();
      }

      return spezialattributeLaden({ SortimentId: null, ArtikelId: article.id })
        .then((response) => {
          const attributes = attributeService.buildAttributeGroups(response.SpezialattributGruppen);

          spezialattributeEinesArtikelsLaden({ ArtikelId: article.id }).then((response) => {
            attributeService.setAttributeGroupsData(attributes, response.Spezialattributsgruppen);

            commit('setAttributes', attributes);
          });
        });
    },

    fetchPackages({ state, commit, rootState, rootGetters }) {
      const article = state.currentArticle;
      const storeId = rootGetters.currentStoreId;

      if (article === null || storeId === null) {
        commit('setPackages', []);

        return Promise.resolve();
      }

      return bezuegeEinesArtikelsLaden({
        ArtikelId: article.id,
        FilialId: storeId,
        Stichtag: formatIso(rootState.currentDate),
      })
        .then((response) => {
          commit('setPackages', response.BezugsInformationen.map(mapPackageData));
        });
    },

    fetchGtins({ state, commit, rootState }) {
      const article = state.currentArticle;
      const date = rootState.currentDate;

      if (article === null) {
        commit('setGtins', []);
        commit('setGtinsPackageVersion', null);

        return Promise.resolve();
      }

      return gtinsEinerVerpackungLaden({
        VerpackungId: article.packageId,
        Stichtag: formatIso(date),
      })
        .then((response) => {
          commit('setGtins', response.GtinDatas.map(mapGtinData));
          commit('setGtinsPackageVersion', response.VerpackungRowVersion);
        });
    },

    fetchPriceData({ commit, state, rootGetters }) {
      const article = state.currentArticle;

      if (article === null) {
        commit('setPrices', null);
        commit('setPriceHistory', []);

        return Promise.resolve();
      }

      return preishistorieLaden({
        ArtikelId: article.id,
        FilialeId: rootGetters.currentStoreId,
      })
        .then((response) => {
          commit('setPrices', response.AktuellePreisdaten);
          commit('setPriceHistory', response.Preishistorie);
        });
    },

    fetchComponentData({ commit, state, rootState }) {
      if (state.currentArticle === null) {
        commit('setComponentData', null);

        return Promise.resolve();
      }

      return artikelverbundLaden({
        ArtikelId: state.currentArticle.id,
        Stichtag: formatIso(rootState.currentDate),
      })
        .then((response) => {
          commit('setComponentData', response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            commit('setComponentData', null);
          }
        });
    },

    fetchCompoundData({ commit, state, rootState, rootGetters }) {
      if (state.currentArticle === null) {
        commit('setCompoundData', null);

        return Promise.resolve();
      }

      return artikelverbundbestandteilinformationLaden({
        ArtikelId: state.currentArticle.id,
        Stichtag: formatIso(rootState.currentDate),
        FilialeId: rootGetters.currentStoreId,
      })
        .then((response) => {
          commit('setCompoundData', response);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            commit('setCompoundData', null);
          }
        });
    },

    fetchArticleEditMeta({ commit, rootGetters, rootState }, id) {
      return artikelAendernValidieren({
        ArtikelId: id,
        KundeId: rootGetters.currentCustomerId,
        Stichtag: formatIso(rootState.currentDate),
      })
        .then((response) => {
          commit('setArticleEditMeta', response);
        });
    },
  },
};

export default storeModule;
