export default {
  provide () {
    return {
      DisableMixin: {
        data: this.injectedDisableData,
      },
    };
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      injectedDisableData: {
        value: this.disabled,
      },
    };
  },

  watch: {
    disabled (value, oldValue) {
      if (value !== oldValue) this.injectedDisableData.value = value;
    },
  },
};
