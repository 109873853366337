import client from '@/api/client';
import { Bearbeitungsstatus } from '.';

export interface Request {
  FilialeId: null;
  WarengruppeId: null;
  LieferantId: string;
  ZeigeNurPreissenkung: boolean;
  ZeigeNurPreiserhoehung: boolean;
  Stichtag: string;
  Bearbeitungsstatus: Bearbeitungsstatus[];
  NurAktiveArtikel: boolean;
  NurGesperrteArtikel: boolean;
  Gefuehrt: boolean;
  TrefferProSeite: number;
  Seite: number;
}

export interface Response {
  Verkaufspreisaenderungen: Verkaufspreisaenderung[];
  AnzahlTreffer: number;
}

export interface Verkaufspreisaenderung {
  Id: string;
  Artikelnummer: number;
  Setnummer: number;
  Bezeichnung: string;
  InhaltMenge: number;
  InhaltFaktor: number;
  InhaltEinheit: string;
  Umsatzsteuersatz: number;
  AlterPreis: number | null;
  NeuerPreis: number | null;
  GueltigAb: string;
  Bearbeitungsstatus: Bearbeitungsstatus;
  Filialverkaufspreis: number;
  Filialeinkaufspreis: number;
}

export async function verkaufspreisaenderungenLaden(request: Request): Promise<Response> {
  return client.request('hof', 'VerkaufspreisaenderungenLaden', request)
    .then(response => response.data);
}
