import client from '@/api/client';

export interface Request {
  ArtikelId: string;
  FilialId: string;
  Stichtag: string;
  VerpackungAnlegenData: VerpackungAnlegenData;
  LiefereinheitAnlegenData: LiefereinheitAnlegenData;
  VertriebslistungAnlegenData: VertriebslistungAnlegenData;
}

export interface VerpackungAnlegenData {
  Gtin: string;
  HausinterneGtinVergeben: boolean;
  MengeArtikelInVerpackung: number;
  Bezeichnung: string;
  Hoehe: number;
  Breite: number;
  Tiefe: number;
  Gewicht: number;
}

export interface LiefereinheitAnlegenData {
  LieferantId: string;
  Lieferantenartikelnummer: string;
  Einkaufspreis: number;
}

export interface VertriebslistungAnlegenData {
  Fabrikabgabepreis: null;
  EmpfohlenerVerkaufspreis: number;
}

export async function bezugseinheitAnlegen(request: Request): Promise<null> {
  return client.request('art', 'HofBezugseinheitAnlegen', request)
    .then(response => response.data);
}
