import { Module } from 'vuex';
import { v4 as uuidV4 } from 'uuid';

import { State as RootState } from '.';

import i18n from '@/i18n';

import { verkaufspreisaenderungenAnhandIdLaden, Bearbeitungsstatus } from '@/api/bitwws';
import { Verkaufspreisaenderung } from '@/api/bitwws/verkaufspreisaenderungenAnhandIdLaden';
import { ArtikelverbundArt } from '../api/bitwws/enums/ArtikelverbundArt';

function formatContents(factor: number, quantity: number, unit: string) {
  const formattedFactor = i18n.n(factor);
  const formattedQuantity = i18n.n(quantity);

  return `${formattedFactor} × ${formattedQuantity} ${unit}`;
}

interface Item {
  reference: string;
  id: string;
  articleSetNumber: number;
  articleName: string;
  articleContents: string;
  taxRate: number;
  sources: Source[];
  selectedSource: Source | null;
  differingPurchasePrices: boolean;
  oldCentralRetailPrice: number;
  newCentralRetailPrice: number;
  oldStoreRetailPrice: number;
  newStoreRetailPrice: number|null;
  editingState: Bearbeitungsstatus;
  saved: boolean;
  error: string|null;
  newPurchasePrice: number | null;
  children?: Item[];
  linkRetailPrice?: boolean;
  multiplyPriceByChildQuantity: boolean;
  childQuantity: number | null;
}

interface Source {
  supplierName: string;
  supplierArticleNumber: string;
  description: string | null;
  centralArticleNumber: string;
  compoundCentralArticleNumber: string | null;
  listingState: string;
  listingType: string;
  purchasePrice: number;
  purchasePricePreviousDay: number;
  packageQuantity: number;
  packageQuantityUnit: string;
  isComponentPurchaseUnit: boolean;
}

interface State {
  items: Item[];
}

function mapData(data: Verkaufspreisaenderung[], linkRetailPrice = false): Item[] {
  return data.map((priceChange) => {
    const sources = priceChange.Bezuege.map((source) => ({
      supplierName: source.LieferantenName,
      supplierArticleNumber: source.Lieferantenartikelnummer,
      description: source.VerpackungBezeichnung,
      centralArticleNumber: source.Zan,
      compoundCentralArticleNumber: source.DisplayZan,
      listingState: source.Bezugslistungsstatus,
      listingType: source.Bezugslistungsart,
      purchasePrice: source.Einkaufspreis,
      purchasePricePreviousDay: source.EinkaufspreisVortag,
      packageQuantity: source.Packeinheitsgroesse,
      packageQuantityUnit: source.Packeinheitseinheit,
      isComponentPurchaseUnit: source.IstUnterartikelBezug,
    }));

    const differingPurchasePrices = sources.some((source, _, sources) => {
      return source.purchasePrice !== sources[0].purchasePrice;
    });
    const { InhaltFaktor, InhaltMenge, InhaltEinheit } = priceChange;

    return {
      reference: uuidV4(),
      id: priceChange.Id,
      articleSetNumber: priceChange.Setnummer,
      articleName: priceChange.Bezeichnung,
      articleContents: formatContents(InhaltFaktor, InhaltMenge, InhaltEinheit),
      taxRate: priceChange.Umsatzsteuersatz,
      sources: sources,
      selectedSource: sources[0] ?? null,
      differingPurchasePrices,
      oldCentralRetailPrice: priceChange.AlterPreis,
      newCentralRetailPrice: priceChange.NeuerPreis,
      oldStoreRetailPrice: priceChange.Filialverkaufspreis,
      newStoreRetailPrice: null,
      editingState: Bearbeitungsstatus.Offen,
      saved: false,
      error: null,
      newPurchasePrice: null,
      children: mapData(priceChange.UnterartikelPreisaenderungen ?? [], false),
      linkRetailPrice,
      childQuantity: priceChange.AnzahlTeile ?? null,
      multiplyPriceByChildQuantity: priceChange.ArtikelverbundArt === ArtikelverbundArt.Multipack,
    };
  });
}

const storeModule: Module<State, RootState> = {
  namespaced: true,

  state: {
    items: [],
  },

  mutations: {
    setItems(state, items: Item[]) {
      state.items = items;
    },

    updateItem(_, {
      item,
      newStoreRetailPrice = item.newStoreRetailPrice,
      editingState = item.editingState,
      selectedSource = item.selectedSource,
      saved = item.saved,
      error = item.error,
      linkRetailPrice = item.linkRetailPrice,
    }) {
      item.newStoreRetailPrice = newStoreRetailPrice;
      item.selectedSource = selectedSource,
      item.editingState = editingState;
      item.saved = saved;
      item.error = error;
      item.linkRetailPrice = linkRetailPrice;
    },

    clearItems(state) {
      state.items = [];
    },

    clearErrors(state) {
      state.items
        .flatMap(item => [item, ...(item.children ?? [])])
        .forEach((item) => {
          item.error = null;
        });
    },
  },

  actions: {
    async loadPriceChangesForEditing({ commit, rootState }, priceChangeIds: string[]) {
      if (rootState.currentFiliale === null) {
        throw new Error('Current store is null.');
      }

      return verkaufspreisaenderungenAnhandIdLaden({
        FilialeId: rootState.currentFiliale.Id,
        AenderungsIds: priceChangeIds,
      })
        .then((data) => {
          commit('setItems', mapData(data.Verkaufspreisaenderungen));
        });
    },

    updateItem({ commit }, { item, parentItem, newStoreRetailPrice, editingState, ...data }) {
      if (data.linkRetailPrice && parentItem) {
        const priceFactor = item.multiplyPriceByChildQuantity && item.childQuantity ? item.childQuantity : 1;
        newStoreRetailPrice = priceFactor * parentItem.newStoreRetailPrice;

        editingState = newStoreRetailPrice === null
          ? Bearbeitungsstatus.Offen
          : Bearbeitungsstatus.Geaendert;
      }

      commit('updateItem', { item, newStoreRetailPrice, editingState, ...data });

      item.children?.forEach((childItem: Item) => {
        if (childItem.linkRetailPrice) {
          editingState = item.newStoreRetailPrice === null
            ? Bearbeitungsstatus.Offen
            : Bearbeitungsstatus.Geaendert;

          const priceFactor = childItem.multiplyPriceByChildQuantity && childItem.childQuantity ? childItem.childQuantity : 1;
          const newChildStoreRetailPrice = priceFactor * item.newStoreRetailPrice;

          commit('updateItem', {
            item: childItem,
            newStoreRetailPrice: newChildStoreRetailPrice,
            editingState,
          });
        }
      });
    },
  },
};

export default storeModule;
