import client from '@/api/client';

export interface LoginOutput {
  Funktionen: string[];
  SessionKey: string;
}

export async function login(): Promise<LoginOutput> {
  return client.post('/BIT.securityApi/Login').then((response) => response.data);
}
