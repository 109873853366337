import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  LieferantenId: UUID;
  KundenId: UUID;
}

export interface Response {
  DarfUnternehmensdatenAendern: boolean;
  UnternehmensId: UUID;
  LieferantenRowVersion: number;
  DarfAbkommensdatenAendern: boolean;
  AbkommenId: UUID;
  AbkommenRowVersion: number;
  AbkommenListungId: UUID;
  AbkommenListungRowVersion: number;
  ZahlungszielTage: number;
  Skonto: number;
  SkontoTage: number;
  Hauptwarengruppe: number;
  SortimenteNeuesWws: Sortiment[];
  SortimenteAs400: Sortiment[];
}

export interface Sortiment {
  Nummer: number;
  Status: number;
}

export async function lieferantenUndAbkommenFuerAendernLaden(request: Request): Promise<Response> {
  return client.request('lua', 'LieferantenUndAbkommenFuerAendernLaden', request)
    .then(response => response.data);
}
