import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  SuchText: string;
  NurLieferantenFuerDezentraleArtikelanlage?: boolean;
  WarengruppeId: string | null;
  FilialeId: string;
  KundenId: UUID | null;
}

export interface Response {
  Lieferantdatas: Lieferant[];
}

export interface Lieferant {
  Id: string;
  BezeichnungUndNummer: string;
}

export async function lieferantSuchen(request: Request): Promise<Response> {
  return client.request('art', 'LieferantSuchen', request)
    .then(response => response.data);
}
