<template>
  <b-skeleton class="skeleton" />
</template>

<script>
import { BSkeleton } from 'bootstrap-vue';

export default {
  name: 'VSkeleton',

  components: {
    BSkeleton,
  },
};
</script>
