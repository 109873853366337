<template>
  <div
    tabindex="-1"
    class="select-autocomplete"
    :class="{
      focus: focused,
      load: loading,
      disabled: finalDisabled,
      empty: value === null && !required,
      'is-valid': state === true,
      'is-invalid': state === false,
    }"
    role="combobox"
    :aria-expanded="opened && options.length > 0"
    :aria-busy="loading"
  >
    <input
      :id="id"
      ref="input"
      v-model="text"
      class="form-control select-autocomplete-input"
      :disabled="finalDisabled"
      :required="required"
      autocomplete="off"
      role="textbox"
      :aria-controls="`${id}-list`"
      :aria-activedescendant="`${id}-option-${focusedIndex}x${focusedColumnIndex}`"
      :aria-haspopup="grid ? 'grid' : null"
      aria-autocomplete="list"
      @focus.prevent="focus()"
      @blur.prevent="blur()"
      @click.self.prevent="open()"
      @keydown.up.prevent="focusPreviousOption()"
      @keydown.down.prevent="focusNextOption()"
      @keydown.right="focusNextColumn($event)"
      @keydown.left="focusPreviousColumn($event)"
      @keydown.home.prevent="focusFirstOption()"
      @keydown.end.prevent="focusLastOption()"
      @keydown.delete="onDelete($event)"
      @keydown.enter="onEnter($event)"
      @keyup.esc.prevent="close()"
      @input.prevent="search()"
    >

    <div
      v-if="loading"
      class="select-autocomplete-loader"
    >
      <v-spinner small />
    </div>

    <button
      v-if="!loading && !required && value !== null"
      class="select-autocomplete-clear"
      type="button"
      tabindex="-1"
      :disabled="finalDisabled"
      @click.prevent="reset(); focusInput()"
    >
      <v-icon icon="times-circle" />
    </button>

    <button
      class="select-autocomplete-toggle"
      type="button"
      tabindex="-1"
      :disabled="finalDisabled"
      @mousedown.stop.prevent="toggle()"
    />

    <ul
      v-if="!grid"
      :id="`${id}-list`"
      ref="list"
      class="select-autocomplete-list"
      :class="[listClass, { open: opened && options.length > 0, above: placement === 'above' }]"
      role="listbox"
      @mousedown.prevent
    >
      <li
        v-for="(option, index) in options"
        :id="`${id}-option-${index}x0`"
        :key="index"
        :class="{ focus: index === focusedIndex }"
        role="option"
        :aria-selected="option === selected"
        @click.prevent="selectOption(index)"
      >
        <slot
          name="item"
          :option="option"
        >
          {{ optionLabel(option) }}
        </slot>
      </li>
    </ul>

    <div
      v-else
      :id="`${id}-list`"
      ref="list"
      class="select-autocomplete-grid"
      :class="[listClass, { open: opened && options.length > 0, above: placement === 'above' }]"
      role="grid"
      @mousedown.prevent
    >
      <div>
        <div
          v-for="(option, index) in options"
          :id="`${id}-option-${index}`"
          :key="index"
          :class="{ focus: index === focusedIndex }"
          role="row"
          :aria-selected="option === selected"
          @click.prevent="selectOption(index)"
        >
          <div
            v-for="(gridColumn, columnIndex) in gridColumns"
            :id="`${id}-option-${index}x${columnIndex}`"
            :key="`${index}x${columnIndex}`"
            :class="{ focus: (index === focusedIndex && columnIndex === focusedColumnIndex) }"
            role="gridcell"
          >
            {{ optionColumnText(gridColumn, option[gridColumn.key]) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
import disabledChild from '@/mixins/disabled-child';

function isTrackValue(value) {
  return typeof value === 'string' || typeof value === 'number';
}

export default {
  name: 'VSelectAutocomplete',

  mixins: [disabledChild],

  props: {
    id: {
      type: String,
      default: () => uniqueId('v-select-autocomplete-'),
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelKey: {
      type: String,
      default: null,
    },
    trackKey: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, String, Number],
      default: null,
    },
    trackKeyAsValue: {
      type: Boolean,
      default: false,
    },
    listClass: {
      type: String,
      default: null,
    },
    grid: {
      type: Boolean,
      default: false,
    },
    gridColumns: {
      type: Array,
      default: () => [],
    },
    state: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    return {
      focused: false,
      opened: false,
      focusedIndex: 0,
      focusedColumnIndex: 0,
      selected: this.value,
      query: '',
      placement: null,
    };
  },

  computed: {
    indexedValues() {
      return Object.assign({}, ...this.options.map((x) => ({[x[this.trackKey]]: x})));
    },

    trackValues() {
      if (this.trackKey) {
        return this.options.map(option => option[this.trackKey]);
      }

      return this.options.map(option => option.toString());
    },

    useTrackKeyAsValue() {
      return this.trackKey !== null && this.trackKeyAsValue;
    },

    text: {
      get() {
        if (!this.opened || this.query.length === 0) {
          return this.selected ? this.optionLabel(this.selected) : '';
        }

        return this.query;
      },
      set(query) {
        this.query = query;
      },
    },
  },

  watch: {
    selected() {
      this.resetFocusIndex();
    },

    value(value) {
      if (value !== null && isTrackValue(value)) {
        if (this.useTrackKeyAsValue) {
          this.selectOptionByTrackKey(value);
        }
      } else {
        this.selected = value;
      }
    },

    trackValues() {
      this.resetFocusIndex();
    },

    query(query) {
      this.$emit('search', query);
    },

    options() {
      if (this.trackKeyAsValue && typeof this.value === 'string') {
        this.selectOptionByTrackKey(this.value);
      }
    },
  },

  created() {
    if (this.useTrackKeyAsValue && this.value !== null && isTrackValue(this.value)) {
      this.selectOptionByTrackKey(this.value);
    }
  },

  methods: {
    resetFocusIndex() {
      let index = 0;

      if (this.selected !== null) {
        index = this.optionIndex(this.selected);
      }

      this.focusedIndex = index === -1 ? 0 : index;
    },

    optionIndex(option) {
      const value = this.trackKey ? option[this.trackKey] : option.toString();

      return this.trackValues.indexOf(value);
    },

    optionLabel(option) {
      if (option === null) {
        return '';
      }

      return this.labelKey ? option[this.labelKey] : option.toString();
    },

    focusInput() {
      this.$nextTick(() => this.$refs.input.focus());
    },

    focus() {
      if (!this.focused) {
        this.focused = true;
        this.$refs.input.select();
      }
    },

    blur() {
      if (this.focused) {
        this.focused = false;
        this.close();
      }
    },

    open() {
      if (!this.opened) {
        this.opened = true;
        this.adjustPlacement();
        this.$nextTick(() => this.adjustScrollPosition());
      }
    },

    close() {
      if (this.opened) {
        this.opened = false;
        this.query = '';
        this.resetFocusIndex();
      }
    },

    toggle() {
      if (this.opened) {
        this.close();
      } else {
        this.open();
      }

      this.focusInput();
    },

    focusNextOption() {
      if (this.opened && this.focusedIndex < this.options.length - 1) {
        this.focusOption(this.focusedIndex + 1);
      }

      this.open();
    },

    focusPreviousOption() {
      if (this.opened && this.focusedIndex > 0) {
        this.focusOption(this.focusedIndex - 1);
      }

      this.open();
    },

    focusFirstOption() {
      this.open();
      this.focusOption(0);
    },

    focusLastOption() {
      this.open();
      this.focusOption(this.options.length - 1);
    },

    focusOption(index) {
      this.focusedIndex = index;
      this.$nextTick(() => this.adjustScrollPosition());
    },

    focusNextColumn(event) {
      if (this.focusedColumnIndex < this.gridColumns.length - 1) {
        this.focusedColumnIndex += 1;
      }

      if (this.grid && this.opened) {
        event.preventDefault();
      }
    },

    focusPreviousColumn(event) {
      if (this.focusedColumnIndex > 0) {
        this.focusedColumnIndex -= 1;
      }

      if (this.grid && this.opened) {
        event.preventDefault();
      }
    },

    updateValue(value) {
      if (this.useTrackKeyAsValue) {
        this.$emit('input', value[this.trackKey]);
      } else {
        this.$emit('input', value);
      }
    },

    selectOption(index) {
      this.close();

      if (this.options.length > index) {
        this.selected = this.options[index] ?? null;

        this.updateValue(this.selected);
      }
    },

    selectOptionByTrackKey(trackKey) {
      this.selected = this.indexedValues[trackKey] ?? null;
    },

    onDelete(event) {
      if (event && event.code === 'delete') {
        event.preventDefault();
        this.reset();
      }
    },

    onEnter(event) {
      if (this.opened) {
        this.selectOption(this.focusedIndex);

        event.preventDefault();
      } else {
        this.$emit('enter', event);
      }
    },

    reset() {
      this.$emit('input', null);
    },

    search() {
      // Clear the selection if the string is completely deleted.
      if (this.query.length === 0) {
        this.reset();
      }

      this.open();
    },

    optionColumnText(column, value) {
      if (column.formatter) {
        return column.formatter(value);
      }

      return value;
    },

    adjustPlacement() {
      const spaceAbove = this.$el.getBoundingClientRect().top;
      const spaceBelow = window.innerHeight - this.$el.getBoundingClientRect().bottom;

      if (spaceBelow > spaceAbove) {
        this.placement = 'below';
      } else {
        this.placement = 'above';
      }
    },

    adjustScrollPosition() {
      const list = this.$refs.list;

      let item = undefined;
      if (this.grid) {
        item = list.querySelectorAll('div[role=row]')[this.focusedIndex];
      } else {
        item = list.querySelectorAll('li[role=option]')[this.focusedIndex];
      }

      if (!item) {
        return;
      }

      const visMin = list.scrollTop;
      const visMax = list.scrollTop + list.clientHeight - item.clientHeight;

      if (item.offsetTop < visMin) {
        list.scrollTop = item.offsetTop;
      } else if (item.offsetTop >= visMax) {
        list.scrollTop = (item.offsetTop - list.clientHeight + item.clientHeight);
      }
    },
  },
};
</script>
