import client from '@/api/client';

export interface Request {
  FilialeId: string;
  PluKreis: string;
  Plunummer: string | null;
}

export interface Response {
  IstPluVergeben: boolean;
  IstPluAusserhalbDesGueltigenBereichs: boolean;
  SindAllePlusDesKreisVergeben: boolean;
}

export async function marktbezogenePluValidieren(request: Request): Promise<Response> {
  return client.request('art', 'MarktbezogenePluValidieren', request)
    .then(response => response.data);
}
