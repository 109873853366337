@@ -0,0 +1,106 @@
<template>
  <div>
    <div ref="editor" />
  </div>
</template>

<script>
import Quill from 'quill';

const defaultOptions = {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['clean'],
    ],
  },
  theme: 'snow',
};

export default {
  name: 'VEditor',

  props: {
    value: {
      type: String,
      default: '',
    },

    options: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      editorContent: '',
    };
  },

  watch: {
    value(newValue) {
      if (newValue !== this.editorContent) {
        this.editorContent = newValue;
        this.setEditorContent(newValue);
      }
    },
  },

  created() {
    this.updateHandler = () => this.update();
  },

  mounted() {
    this.initializeEditor();
    this.registerEvents();
    this.setEditorContent(this.value);
  },

  beforeDestroy() {
    this.unregisterEvents();
    this.quill = null;
  },

  methods: {
    initializeEditor() {
      this.quill = new Quill(this.$refs.editor, Object.assign({}, defaultOptions, this.options));

      const keyboard = this.quill.getModule('keyboard');

      // Remove tab binding
      delete keyboard.bindings[9];
    },

    registerEvents() {
      this.quill.on('text-change', this.updateHandler);
    },

    unregisterEvents() {
      this.quill.off('text-change', this.updateHandler);
    },

    update() {
      this.editorContent = this.getEditorContent();
      this.$emit('input', this.editorContent);
    },

    setEditorContent(value) {
      this.$nextTick(() => {
        this.quill.setContents(this.quill.clipboard.convert(value));
      });
    },

    getEditorContent() {
      const editorContent = this.quill.getText() ? this.quill.root.innerHTML : '';

      if (editorContent === '<p><br></p>') {
        return '';
      }

      return editorContent;
    },
  },
};
</script>

<style lang="scss">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
</style>
