export default {
  inject: {
    DisableMixin: {
      default: null,
    },
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    finalDisabled () {
      return this.disabled || (this.DisableMixin && this.DisableMixin.data.value);
    },
  },
};
