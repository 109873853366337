
import { BContainer } from 'bootstrap-vue';

export default {
  name: 'VContainer',

  components: {
    BContainer,
  },

  inheritAttrs: false,
};
