<template>
  <b-form-group
    v-bind="$attrs"
    :label-class="{ 'font-weight-bold': required }"
    :disabled="finalDisabled"
  >
    <template
      v-if="label"
      #label
    >
      <template v-if="labelAbbr">
        <abbr :title="labelAbbr">{{ label }}</abbr>
      </template>

      <template v-else>
        <span>{{ label }}</span>
      </template>
    </template>

    <slot />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import disabledChild from '@/mixins/disabled-child';

export default {
  name: 'VFormField',

  components: {
    BFormGroup,
  },

  mixins: [
    disabledChild,
  ],

  inheritAttrs: false,

  props: {
    required: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: null,
    },

    labelAbbr: {
      type: String,
      default: null,
    },
  },
};
</script>
