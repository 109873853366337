import Vue from 'vue';
import { Module } from 'vuex';
import { v4 as uuidV4 } from 'uuid';

import { State as RootState } from '.';

import { artikelFuerVerkaufspreisaenderungLaden } from '@/api/bitwws';
import { Artikel } from '@/api/bitwws/artikelFuerVerkaufspreisaenderungLaden';
import { UUID } from '@/api/bitwws/types';
import { fetchPurchaseItemByPriceEditItem } from '@/utils/fetchPurchaseItem';
import { ArtikelverbundArt } from '@/api/bitwws/enums/ArtikelverbundArt';

interface Item {
  reference: string;
  id: string;
  articleSetNumber: number;
  articleName: string;
  articleContents: string;
  taxRate: number;
  sources: Source[];
  selectedSource: Source | null;
  differingPurchasePrices: boolean;
  oldCentralRetailPrice: number | null;
  newCentralRetailPrice: number | null;
  oldStoreRetailPrice: number | null;
  newStoreRetailPrice: number|null;
  saved: boolean;
  error: string|null;
  newPurchasePrice: number | null;
  children?: Item[];
  linkRetailPrice?: boolean;
  definePriceByChildren: boolean;
  multiplyPriceByChildQuantity: boolean;
  childQuantity: number | null;
  isChild: boolean;
}

export interface Source {
  supplierName: string;
  supplierArticleNumber: string;
  description: string | null;
  centralArticleNumber: string;
  compoundCentralArticleNumber: string | null;
  listingState: string;
  purchasePrice: number;
  purchasePricePreviousDay: number;
  packageQuantity: number;
  packageQuantityUnit: string;
  central: boolean;
  isComponentPurchaseUnit: boolean;
  purchasePriceEditable: boolean;
  purchasePriceNotEditableReason: string | null;
}

interface State {
  items: Item[];
}

export interface PriceEditItem {
  articleId: UUID,
  purchaseUnitZan: string,
}

function mapData(data: Artikel[], linkRetailPrice = false, priceEditItems: PriceEditItem[], isChild = false): Item[] {
  return data.map((article) => {
    const sources = article.Bezugseinheiten.map((source) => ({
      id: source.BezugseinheitId,
      supplierName: source.LieferantenName,
      supplierArticleNumber: source.Lieferantenartikelnummer,
      description: source.VerpackungBezeichnung,
      centralArticleNumber: source.Zan,
      compoundCentralArticleNumber: source.DisplayZan,
      listingState: source.Bezugslistungsstatus,
      listingType: source.Bezugslistungsart,
      purchasePrice: source.Einkaufspreis,
      purchasePricePreviousDay: source.EinkaufspreisVortag,
      packageQuantity: source.Packeinheitsgroesse,
      packageQuantityUnit: source.Packeinheitseinheit,
      central: source.IstZentralerBezug,
      isComponentPurchaseUnit: source.IstUnterartikelBezug,
      purchasePriceEditable: source.IstEkAenderbar,
      purchasePriceNotEditableReason: source.EkNichtAenderbarGrund,
    }));

    const differingPurchasePrices = sources.some((source, _, sources) => {
      return source.purchasePrice !== sources[0].purchasePrice;
    });

    return {
      reference: uuidV4(),
      id: article.Id,
      articleSetNumber: article.Setnummer,
      articleName: article.Bezeichnung,
      articleContents: article.Inhalt,
      taxRate: article.Umsatzsteuersatz,
      sources: sources,
      selectedSource: fetchPurchaseItemByPriceEditItem(sources , priceEditItems.find(p => p.articleId === article.Id)),
      differingPurchasePrices,
      oldCentralRetailPrice: article.Zentralverkaufspreis,
      newCentralRetailPrice: null,
      oldStoreRetailPrice: article.Filialverkaufspreis,
      newStoreRetailPrice: null,
      newPurchasePrice: null,
      saved: false,
      error: null,
      children: mapData(article.Unterartikel ?? [], article.ArtikelverbundArt === ArtikelverbundArt.MischgebindePreisgleich, priceEditItems, true),
      linkRetailPrice: linkRetailPrice,
      definePriceByChildren: article.ArtikelverbundArt === ArtikelverbundArt.MischgebindePreisungleich,
      multiplyPriceByChildQuantity: article.ArtikelverbundArt === ArtikelverbundArt.Multipack,
      childQuantity: article.AnzahlTeile ?? null,
      isChild,
    };
  });
}

const storeModule: Module<State, RootState> = {
  namespaced: true,

  state: {
    items: [],
  },

  mutations: {
    setItems(state, items: Item[]) {
      Vue.set(state, 'items', items);
    },

    updateItem(_, {
      item,
      newStoreRetailPrice = item.newStoreRetailPrice,
      newPurchasePrice = item.newPurchasePrice,
      selectedSource = item.selectedSource,
      saved = item.saved,
      error = item.error,
      linkRetailPrice = item.linkRetailPrice,
    }) {
      item.newStoreRetailPrice = newStoreRetailPrice;
      item.newPurchasePrice = newPurchasePrice;
      item.selectedSource = selectedSource;
      item.saved = saved;
      item.error = error;
      item.linkRetailPrice = linkRetailPrice;
    },

    clearItems(state) {
      Vue.set(state, 'items', []);
    },

    clearErrors(state) {
      state.items
        .flatMap(item => [item, ...(item.children ?? [])])
        .forEach((item) => {
          item.error = null;
        });
    },
  },

  actions: {
    async loadArticlesForEditing({ commit, rootState }, priceEditItems: PriceEditItem[]) {
      if (rootState.currentFiliale === null) {
        throw new Error('Current store is null.');
      }

      return artikelFuerVerkaufspreisaenderungLaden({
        FilialeId: rootState.currentFiliale.Id,
        ArtikelIds: priceEditItems.map(p => p.articleId),
      })
        .then((data) => {
          commit('setItems', mapData(data.Artikel, false, priceEditItems));
        });
    },

    updateItem({ commit }, { item, parentItem, newStoreRetailPrice, ...data }) {
      if (data.linkRetailPrice && parentItem) {
        const priceFactor = item.multiplyPriceByChildQuantity && item.childQuantity ? item.childQuantity : 1;
        newStoreRetailPrice = priceFactor * parentItem.newStoreRetailPrice ?? parentItem.oldStoreRetailPrice;
      }

      commit('updateItem', { item, newStoreRetailPrice, ...data });

      item.children?.forEach((childItem: Item) => {
        if (childItem.linkRetailPrice) {
          const priceFactor = childItem.multiplyPriceByChildQuantity && childItem.childQuantity ? childItem.childQuantity : 1;
          const newChildStoreRetailPrice = priceFactor * item.newStoreRetailPrice;

          commit('updateItem', { item: childItem, newStoreRetailPrice: newChildStoreRetailPrice });
        }
      });

      if (parentItem && parentItem.definePriceByChildren) {
        let sum = null;
        if(parentItem.children.some((childItem: Item) => childItem.newStoreRetailPrice !== null)){
          sum = parentItem.children.reduce(
            (sum: number, childItem: Item) => sum
              + (childItem.newStoreRetailPrice ?? childItem.oldStoreRetailPrice ?? 0)
              * (childItem.childQuantity ?? 0),
            0,
          );
        }

        commit('updateItem', { item:parentItem, newStoreRetailPrice: sum });
      }
    },
  },
};

export default storeModule;
