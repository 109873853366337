import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  LieferantId: UUID;
  KundeId: UUID;
  MandantNummer: number;
  Stichtag: string;
}

export interface Response {
  DezentraleLieferantenNummer: number;
  DarfUnternehmenGeaendertWerden: boolean;
  UnternehmenId: UUID;
  LieferantId: UUID;
  Gln: string;
  Grunddaten: {
    Firma: string;
    Kurzname: string;
    Geschaeftsbereich: string;
    UmsatzsteuerIdentnummer: string;
    Steuernummer: string;
    Verpackungsregistrierungsnummer: string;
  };
  Zustellangabe: {
    Plz: string;
    Strasse: string;
    Hausnummer: string;
    Zustelltext: string;
    Ort: string;
    NationalitaetName: string;
    NationalitaetKuerzel: string;
  } | null;
  Abholangabe: {
    Postfach: string;
    Plz: string;
    Ort: string;
    NationalitaetName: string;
    NationalitaetKuerzel: string;
  } | null;
  Kommunikationen: {
    Art: string;
    Bezeichnung: string;
    Wert: string;
  }[];
  Zahlungsbedingungen: {
    ZahlungszielTage: number;
    Skonto: number;
    SkontoTage: number;
  };
  Hauptwarengruppe: {
    Id: number;
    Beschreibung: string;
    Nummer: number;
  };
  SortimenteNeuesWws: {
    Id: number;
    Beschreibung: string;
    Nummer: number;
    Status: number;
  }[];
  SortimenteAs400: {
    Id: number;
    Beschreibung: string;
    Nummer: number;
    Status: number;
  }[];
}

export async function lieferantLaden(request: Request): Promise<Response> {
  return client.request('lua', 'LieferantLaden', request)
    .then(response => response.data);
}
