
import Vue from 'vue';
import { BNavbarBrand } from 'bootstrap-vue';

export default Vue.extend({
  name: 'VNavbarTitle',

  components: {
    BNavbarBrand,
  },
});
