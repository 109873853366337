<template>
  <div class="no-stores">
    <v-empty-state
      icon="exclamation-circle"
      :headline="$t('components.no_stores.no_stores_headline')"
      variant="danger"
    >
      {{ $t('components.no_stores.no_stores_message') }}
    </v-empty-state>
  </div>
</template>

<script>
export default {
  name: 'NoStores',
};
</script>
