import client from '@/api/client';
import { UUID } from '@/api/bitwws/types';

export interface Request {
  ArtikelId: UUID;
  Stichtag: string;
}

export interface Response {
  ArtikelId: UUID;
  ArtikelBezeichnung: string;
  AnzahlInVerpackung: number;
  VerpackungBezeichnung: string | null;
  Gtin: number;
}

export async function unterartikelLaden(request: Request): Promise<Response> {
  return client.request('art', 'UnterartikelLaden', request)
    .then(response => response.data);
}
