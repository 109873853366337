import { RawLocation } from 'vue-router';

export interface Item {
  text: string;
  route: RawLocation;
  icon: string;
  children?: ChildItem[];
}

export interface ChildItem {
  text: string;
  route: RawLocation;
}

const items: Array<Item> = [
  {
    text: 'navigation.articles',
    route: '/price-changes',
    icon: 'box-open',
    children: [
      {
        text: 'navigation.articles_price_changes',
        route: '/price-changes',
      },
      {
        text: 'navigation.articles_list',
        route: '/articles',
      },
      {
        text: 'navigation.articles_create',
        route: '/articles/create',
      },
    ],
  },
  {
    text: 'navigation.suppliers',
    route: '/suppliers',
    icon: 'dolly',
    children: [
      {
        text: 'navigation.suppliers_list',
        route: '/suppliers',
      },
      {
        text: 'navigation.suppliers_create',
        route: '/suppliers/create',
      },
    ],
  },
  {
    text: 'navigation.listing_requests',
    route: '/listing-requests',
    icon: 'tasks',
  },
  {
    text: 'navigation.store_users',
    route: '/store-users',
    icon: 'users',
  },
  {
    text: 'navigation.admin',
    route: '/admin/users',
    icon: 'users',
    children: [
      {
        text: 'navigation.users',
        route: '/admin/users',
      },
    ],
  },
];

export default items;
