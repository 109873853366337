export const maxDate = new Date(Date.UTC(9999, 11, 31));

/**
 * Formats a date into ISO format (YYYY-MM-DD).
 *
 * @param {Date} date
 * @returns {string}
 */
export function formatIso(date: Date): string {
  return date.toISOString().split('T')[0];
}
