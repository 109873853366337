import client from '@/api/client';
import { Informationsart } from '@/api/bitwws/enums/Informationsart';
import { ArtikelverbundArt } from './enums/ArtikelverbundArt';
import { UUID } from './types';

export interface ArtikelAnlegenData {
  UmsatzsteuerId: string | null;
  Bezeichnung: string | null;
  SortimentId: string | null;
  HauptplatzierungId: string | null;
  WarengruppeId: string | null;
  ArtDerVerkaufseinheit: number | null;
  VerpackungsartId: string | null;
  Inhaltanzahl: string | null;
  Inhaltmenge: string | null;
  InhalteinheitId: string | null;
  PreiseingabeAnKasse: boolean;
  IstRabattfaehig: boolean | null;
  ArtikelinformationDataList: ArtikelinformationData[];
}

export interface ArtikelinformationData {
  Informationsart: Informationsart;
  Information: string;
}

export interface VerpackungAnlegenData {
  Hoehe: string;
  Tiefe: string;
  Breite: string;
  Gewicht: string;
  HausinterneGtinVergeben: false;
  Gtin: string;
  GtinDataList: GtinData[];
  VerkaufsverpackungData?: {
    Regaltext: string;
    Bontext: string;
    FreiePluVergeben: boolean;
    PluKreis: number;
    Plunummer: string;
  } | null;
  BezugsgebindeData: {
    LiefereinheitAnlegenData: {
      LieferantId: string;
      Lieferantenartikelnummer: string;
    };
  };
  ArtikelleergutZuordnenData: {
    LeergutId: string;
  };
}

export interface GtinData {
  HausinterneGtin: boolean;
  IstHauptgtin: boolean;
  Gtin: string;
}

export interface Spezialattribut {
  SpezialattributTyp: string;
  SpezialattributKuerzel: string;
  DomaenenKuerzel?: string[];
  Wert?: unknown;
  EinheitId?: string;
  NaehrwertDataList?: Array<{
    Kennzeichen: string;
    Wert: number|null;
  }>;
}

export interface MischgebindeAnlegenData {
  ArtikelVerbundArt: ArtikelverbundArt;
  HauptartikelVerkaufspreisUebernehmen: boolean;
  GesamtAnzahlTeile: number;
  ArtikelverbundbestandteilDatas: ArtikelverbundbestandteilData[];
}

export interface ArtikelverbundbestandteilData {
  Anzahl: number;
  ArtikelId: UUID;
  Einkaufspreis: string;
}

export interface Request {
  ArtikelAnlegenData: ArtikelAnlegenData;
  VerpackungAnlegenDataList: VerpackungAnlegenData[];
  Verkaufspreis: string | null;
  Einkaufspreis: string | null;
  FilialeId: string | null;
  SpezialattributAnlegenDataList: Spezialattribut[];
  MischgebindeAnlegenData: MischgebindeAnlegenData;
  SeenWarnings: string[] | null;
}

export interface Response {
  ArtikelId: string;
  VerpackungId: string;
  LiefereinheitId: string;
  BezugslistungId: string;
  Warnungen: { [key: string]: string } | null;
}

export async function artikelAnlegen(request: Request): Promise<Response> {
  return client.request('art', 'HofArtikelAnlegen', request)
    .then(response => response.data);
}
