import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  VerpackungId: UUID;
  VerpackungRowVersion: number;
  GueltigBis: string;
  GtinId: UUID;
  KundeId: UUID;
}

export async function gtinLoeschen(request: Request): Promise<null> {
  return client.request('art', 'GtinLoeschen', request)
    .then(response => response.data);
}
