
import Vue from 'vue';

export default Vue.extend({
  name: 'VFormActions',

  computed: {
    hasSecondarySlot(): boolean {
      return this.$slots['secondary'] !== undefined;
    },
  },
});
