import loginService from './login-service';

/**
 * Refresh token helper which manages a cache of requests to retry them after the token got
 * refreshed.
 */
export default class TokenRefresher {
  private refreshing: boolean;
  private subscribers: Array<(token: string) => void>;
  private errorSubscribers: Array<(error: Error) => void>;

  /**
   * E. g. "/oauth/token"
   */
  private whitelist: string[];

  constructor() {
    this.refreshing = false;
    this.subscribers = [];
    this.errorSubscribers = [];
    this.whitelist = [];
  }

  /**
   * Subscribe a new callback to the cache queue
   */
  subscribe(
    callback: (token: string) => unknown = () => undefined,
    errorCallback: (error: Error) => void = () => undefined,
  ) {
    this.subscribers.push(callback);
    this.errorSubscribers.push(errorCallback);
  }

  /**
   * Event handler which will be fired when the token got refresh. It iterates over the registered
   * subscribers and fires the callbacks with the new token.
   *
   * @param {string} token - Renewed access token
   */
  private onRefreshToken(token: string) {
    this.subscribers = this.subscribers.reduce((accumulator, callback) => {
      callback.call(null, token);
      return accumulator;
    }, []);
    this.errorSubscribers = [];
  }

  /**
   * Event handler which will be fired when the refresh token couldn't be fetched from the API.
   */
  private onRefreshTokenFailed(err: Error) {
    this.errorSubscribers = this.errorSubscribers.reduce((accumulator, callback) => {
      callback.call(null, err);
      return accumulator;
    }, []);
    this.subscribers = [];
  }

  /**
   * Fires the refresh token request and renews the bearer authentication in the login service.
   */
  public async fireRefreshTokenRequest() {
    this.refreshing = true;

    return loginService.refreshToken().then((newToken) => {
      this.onRefreshToken(newToken);
    }).finally(() => {
      this.refreshing = false;
    }).catch((error: Error) => {
      loginService.logout();
      this.onRefreshTokenFailed(error);
      return Promise.reject();
    });
  }

  public isWhitelisted(resource: string) {
    return this.whitelist.includes(resource);
  }

  public get isRefreshing() {
    return this.refreshing;
  }
}
