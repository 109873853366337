export type AttributeOptions = Record<string, unknown>;

export interface AttributeGroup {
  id: string;
  label: string;
  attributes: Attribute[];
}

export interface Attribute {
  type: string;
  id: string;
  label: string;
  required: boolean;
  options?: AttributeOptions;
  value: unknown;
  defaultValue: unknown;
  when?: (attributes: Attribute[]) => boolean;
  isEmpty?: (attribute: Attribute) => boolean;
}

export function getAttributeValue(attributes: Attribute[], id: string): unknown | undefined {
  return attributes.find(attribute => attribute.id === id)?.value;
}

export function whenAttribute(attributes: Attribute[], id: string): boolean {
  const attribute = attributes.find(attribute => attribute.id === id);

  if (!attribute) {
    return false;
  }

  if (!attribute.when) {
    return true;
  }

  return attribute.when(attributes);
}

export function isEmpty(attribute: Attribute): boolean {
  if (!attribute.isEmpty) {
    return false;
  }

  return attribute.isEmpty(attribute);
}
