
import Vue from 'vue';
import { BNavItemDropdown } from 'bootstrap-vue';

export default Vue.extend({
  name: 'VNavItemDropdown',

  components: {
    BNavItemDropdown,
  },

  inheritAttrs: false,
});
