
import { BInputGroupAppend } from 'bootstrap-vue';

export default {
  name: 'VInputGroupAppend',

  components: {
    BInputGroupAppend,
  },

  inheritAttrs: false,
};
