import client from '@/api/client';
import { UUID } from '@/api/bitwws/types';

export interface Request {
  FilialId: UUID;
  ArtikelId: UUID;
  Stichtag: string;
}

export interface Response {
  Verkaufspreis: number;
}

export async function filialVerkaufspreisLaden(request: Request): Promise<Response> {
  return client.request('hof', 'FilialVerkaufspreisLaden', request).then(response => response.data);
}
