import { Module } from 'vuex';
import { State as RootState } from '.';
import { formatIso } from '@/utils/date';
import { lieferantSuchen } from '@/api/bitwws/lua/lieferantSuchen';

interface State {
  suppliers: [];
  searchData: {
    term: string;
  };
}

const storeModule: Module<State, RootState> = {
  namespaced: true,

  state: {
    suppliers: [],

    searchData: {
      term: '',
    },
  },

  mutations: {
    setSuppliers(state, suppliers) {
      state.suppliers = suppliers;
    },

    updateSearchData(state, { searchData, term = searchData.term }) {
      state.searchData.term = term;
    },

    resetSearchData(state) {
      state.searchData.term = '';
    },
  },

  actions: {
    fetchSuppliers({ rootState, rootGetters, state, commit }) {
      return lieferantSuchen({
        KundeId: rootGetters.currentCustomerId,
        Stichtag: formatIso(rootState.currentDate),
        Suchtext: state.searchData.term,
      })
        .then((response) => {
          commit('setSuppliers', response.Lieferanten);
        });
    },
  },
};

export default storeModule;
