<template>
  <button
    class="navbar-sidebar-toggler"
    :title="buttonLabel"
    v-on="$listeners"
  >
    <span
      class="navbar-toggler-icon"
      aria-hidden="true"
    />
  </button>
</template>

<script>
export default {
  name: 'VNavbarSidebarToggler',

  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonLabel() {
      return this.opened
        ? this.$t('components.navbar_sidebar_toggler.close')
        : this.$t('components.navbar_sidebar_toggler.open');
    },
  },
};
</script>
