import { Bezug } from '@/api/bitwws/artikelFuerSucheLaden';
import { Source, PriceEditItem } from '../store/price-edit';

export function fetchPurchaseItemByPriceEditItem(purchaseUnits: Source[], priceEditItem?: PriceEditItem): Source|null {
  if (priceEditItem) {
    const purchaseUnit = purchaseUnits.find(p => priceEditItem.purchaseUnitZan === p.centralArticleNumber);
    if (purchaseUnit) {
      return purchaseUnit;
    }
  }
  return purchaseUnits[0] ?? null;
}

export function fetchPurchaseItemBySupplierItemNumbers(purchaseUnits: Bezug[], supplierItemNumbers: string | null): Bezug|null {
  if (!supplierItemNumbers) {
    return purchaseUnits[0] ?? null;
  }

  const splitNumbers = supplierItemNumbers.split(',').map(num => num.trim().toLowerCase());

  return purchaseUnits.find(p => splitNumbers.includes(p.Lieferantenartikelnummer.trim().toLowerCase())) ?? null;
}
