import client from '@/api/client';
import { UUID } from './types';
import { Hauptkommunikationsart } from './enums/Hauptkommunikationsart';

export interface Request {
  UnternehmenId: UUID;
  Firma: string;
  Kurzname: string;
  Geschaeftsbereich: string;
  HausinterneGlnVergeben: boolean;
  Gln: string;
  UmsatzsteuerIdentnummer: string;
  Steuernummer: string;
  Verpackungsregistrierungsnummer: string;
  RowVersion: number;
  Hauptkommunikationsart: Hauptkommunikationsart;
  HauptkommunikationsartGeaendert: boolean;
  Zustellangabe: Zustellangabe;
  ZustellangabeGeaendert: boolean;
  Abholangabe: Abholangabe | null;
  Kommunikationsdaten: Kommunikationsdaten[];
  KommunikationsdatenGeaendert: boolean;
}

export interface Zustellangabe {
  Zustelltext: string;
  Strasse: string;
  Hausnummer: string;
  Plz: string;
  Ort: string;
  NationId: UUID;
}

export interface Abholangabe {
  Postfach: string;
  Plz: string;
  Ort: string;
  NationId: UUID;
}

export interface Kommunikationsdaten {
  Art: string;
  Bezeichnung: string;
  NummerAdresse: string;
}

export async function unternehmenAendern(request: Request): Promise<null> {
  return client.request('org', 'UnternehmenAendern', request)
    .then(response => response.data);
}
