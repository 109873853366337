<template>
  <span class="not-available">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'VNotAvailable',
};
</script>
