import client from '@/api/client';
import { UUID } from './types';

export interface Response {
  Nationen: Nation[];
}

export interface Nation {
  Id: UUID;
  Name: string;
}

export async function nationenLaden(): Promise<Response> {
  return client.request('org', 'NationenLaden', {})
    .then(response => response.data);
}
