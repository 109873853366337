<template>
  <div v-if="wrappedTarget">
    <div ref="target">
      <slot />
    </div>

    <b-tooltip
      v-if="target"
      :target="target"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot name="content">
        {{ content }}
      </slot>
    </b-tooltip>
  </div>

  <b-tooltip
    v-else
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </b-tooltip>
</template>

<script>
import { BTooltip } from 'bootstrap-vue';

export default {
  name: 'VTooltip',

  components: {
    BTooltip,
  },

  inheritAttrs: false,

  props: {
    content: {
      type: String,
      default: null,
    },

    wrappedTarget: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      target: null,
    };
  },

  mounted() {
    this.target = this.$refs.target ?? null;
  },
};
</script>
