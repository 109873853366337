import client from '@/api/client';

export interface Request {
  SuchText: string;
}

export interface Response {
  Herstellerdatas: Hersteller[];
}

export interface Hersteller {
  Id: string;
  BezeichnungUndNummer: string;
}

export async function herstellerSuchen(request: Request): Promise<Response> {
  return client.request('art', 'HerstellerSuchen', request)
    .then(response => response.data);
}
