<template>
  <b-form-invalid-feedback v-bind="$attrs">
    <slot />
  </b-form-invalid-feedback>
</template>

<script>
import { BFormInvalidFeedback } from 'bootstrap-vue';

export default {
  name: 'VInvalidFeedback',

  components: {
    BFormInvalidFeedback,
  },

  inheritAttrs: false,
};
</script>
