import Vue from 'vue';
import Vuex from 'vuex';
import LocalStorage from '@/utils/local-storage';

import { login } from '@/api/security';
import { filialenLaden } from '@/api/bitwws';
import { Filiale } from '@/api/bitwws/filialenLaden';

import article from './article';
import articleEdit from './article-edit';
import articleList from './article-list';
import priceEdit from './price-edit';
import priceChange from './price-change';
import supplier from './supplier';
import ui from './ui';
import systemConfig from './system-config';
import listingRequest from './listing-request';
import { UUID } from '@/api/bitwws/types';

Vue.use(Vuex);

export interface State {
  initialized: boolean;
  currentDate: Date;
  user: object|null;
  permissions: string[];
  stores: Filiale[];
  currentFiliale: Filiale|null;
  priceEditListItems: PriceEditListItems[];
  priceChangeEditListIds: string[];
}


export interface PriceEditListItems {
  articleId: UUID,
  purchaseUnitId: UUID,
}

export default new Vuex.Store<State>({
  state: {
    initialized: false,
    currentDate: new Date(),
    user: LocalStorage.getJson<object>('user'),
    permissions: [],
    stores: [],
    currentFiliale: null,
    priceEditListItems: [],
    priceChangeEditListIds: [],
  },
  getters: {
    hasPermission: (state) => (permission: string) => {
      return state.permissions.includes(permission);
    },

    currentStoreId(state) {
      return state.currentFiliale?.Id;
    },

    currentCustomerId(state) {
      return state.currentFiliale?.KundeId;
    },

    currentTenantNumber(state) {
      return state.currentFiliale?.Mandant;
    },
  },
  mutations: {
    setInitialized(state) {
      state.initialized = true;
    },
    setUser(state, value) {
      state.user = value;
      LocalStorage.setJson('user', state.user);
    },
    setPermissions(state, value) {
      state.permissions = value;
    },
    removeUser(state) {
      state.user = null;
      localStorage.removeItem('user');
    },
    setStores(state, value) {
      state.stores = value;
    },
    setCurrentFiliale(state, value) {
      state.currentFiliale = value;
    },
    setPriceEditListItems(state, items) {
      state.priceEditListItems = items;
    },
    clearPriceEditListItems(state) {
      state.priceEditListItems = [];
    },
    setPriceChangeEditListId(state, priceChangeIds) {
      state.priceChangeEditListIds = priceChangeIds;
    },
    clearPriceChangeEditListId(state) {
      state.priceChangeEditListIds = [];
    },
  },
  actions: {
    async initialize({ commit, dispatch }) {
      return dispatch('systemConfig/fetchConfig')
        .then(() => dispatch('fetchPermissions'))
        .finally(() => commit('setInitialized'));
    },

    async fetchPermissions({ commit, state }) {
      if (state.user === null) {
        return Promise.resolve();
      }

      return login().then((response) => {
        commit('setPermissions', response.Funktionen);
      });
    },

    async fetchStores({ commit, state }) {
      return filialenLaden().then((response) => {
        commit('setStores', response.Filialen);

        if (state.stores.length > 0) {
          commit('setCurrentFiliale', state.stores[0]);
        }
      });
    },
  },
  modules: {
    article,
    articleEdit,
    articleList,
    priceEdit,
    priceChange,
    supplier,
    ui,
    systemConfig,
    listingRequest,
  },
});
