
import { BDropdownItem } from 'bootstrap-vue';

export default {
  name: 'VDropdownItem',

  components: {
    BDropdownItem,
  },

  inheritAttrs: false,

  props: {
    danger: {
      type: Boolean,
      default: false,
    },
  },
};
