<template>
  <k-localization language="de">
    <k-intl locale="de">
      <k-grid
        :data-items="pagedItems"
        :columns="computedFields"
        :sortable="sortable"
        :sort="sort"
        :filterable="filterable"
        :filter="filter"
        :total="filteredItems.length"
        :skip="page.skip"
        :take="page.take"
        :pageable="pageableOptions"
        :page-size="pageSize"
        selected-field="selected"
        :data-item-key="dataItemKey"
        v-bind="$attrs"
        @sortchange="updateSort"
        @filterchange="updateFilter"
        @pagechange="updatePage"
        v-on="$listeners"
        @rowclick="onRowClick"
        @selectionchange="onRowClick"
        @headerselectionchange="onHeaderSelectionChange"
      >
        <template #action="{ props }">
          <td class="data-grid-actions">
            <template v-if="getActions(props.dataItem).length > 0">
              <v-dropdown
                size="sm"
                right
              >
                <template #button-content>
                  <v-icon
                    icon="ellipsis-v"
                    fixed-width
                  />
                  <span class="sr-only">
                    {{ $t('components.table.actions') }}
                  </span>
                </template>

                <template v-for="(action) in getActions(props.dataItem)">
                  <v-dropdown-item
                    v-if="action.to"
                    :key="action.id"
                    :danger="action.danger"
                    :to="getRoute(action.to, props.dataItem)"
                  >
                    {{ action.label }}
                  </v-dropdown-item>

                  <v-dropdown-item-button
                    v-else
                    :key="action.id"
                    :danger="action.danger"
                    @click="action.handler(props.dataItem)"
                  >
                    {{ action.label }}
                  </v-dropdown-item-button>
                </template>
              </v-dropdown>
            </template>
          </td>
        </template>

        <template
          v-for="(_, slot) of $scopedSlots"
          #[slot]="scope"
        >
          <slot
            v-bind="scope"
            :name="slot"
          />
        </template>

        <k-grid-no-records>
          <slot name="empty-state" />
        </k-grid-no-records>
      </k-grid>
    </k-intl>
  </k-localization>
</template>

<script>
import { Grid, GridNoRecords } from '@progress/kendo-vue-grid';
import { orderBy } from '@progress/kendo-data-query';
import { IntlProvider } from '@/components/kendo/IntlProvider';
import { LocalizationProvider } from '@/components/kendo/LocalizationProvider';
import { uniqueId } from 'lodash';
import { filterBy } from '@progress/kendo-data-query';
import Vue from 'vue';

export default {
  name: 'VDataGrid',

  components: {
    KGrid: Grid,
    KGridNoRecords: GridNoRecords,
    KLocalization: LocalizationProvider,
    KIntl: IntlProvider,
  },

  inheritAttrs: false,

  props: {
    items: {
      type: Array,
      required: true,
    },
    actions: {
      type: Array,
      default: () => [],
    },

    dataItemKey: {
      type: String,
      default: null,
    },

    columns: {
      type: Array,
      required: true,
    },

    sort: {
      type: Array,
      default: () => [],
    },

    sortable: {
      type: Boolean,
      default: false,
    },

    selectable: {
      type: Boolean,
      default: false,
    },

    filterable: {
      type: Boolean,
      default: false,
    },

    pageable: {
      type: Boolean,
      default: false,
    },

    pageSizes: {
      type: Array,
      default: () => [25, 50, 100],
    },

    pageSize: {
      type: Number,
      default: 25,
    },

    filter: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      page: {
        skip: 0,
        take: this.pageSize,
      },
      internalItems: [],
    };
  },

  computed: {
    pageableOptions() {
      if (!this.pageable) {
        return false;
      }

      return {
        pageSizes: this.pageSizes,
      };
    },
    computedFields() {
      let columns = this.columns;

      if (this.actions.length > 0) {
        columns = [
          ...columns,
          {
            cell: 'action',
            filterable:false,
            columnMenu: false,
            width: '60px',
          },
        ];
      }

      if (this.selectable) {
        columns = [
          {
            field: 'selected',
            width: '50px',
            columnMenu: false,
            headerSelectionValue: this.areAllSelected,
          },
          ...columns,
        ];
      }

      return columns;
    },

    areAllSelected () {
      return !this.internalItems.some(item => item.selected === false);
    },

    filteredItems() {
      if (this.filterable) {
        return filterBy(this.internalItems, this.filter);
      }

      return this.internalItems;
    },

    sortedItems() {
      if (this.sortable) {
        return orderBy(this.filteredItems, this.sort);
      }

      return this.filteredItems;
    },

    pagedItems() {
      if (this.pageable) {
        return this.sortedItems.slice(this.page.skip, this.page.take + this.page.skip);
      }

      return this.sortedItems;
    },
  },

  watch: {
    items: {
      immediate: true,
      handler(){
        this.internalItems = this.items.map((item) => ({ ...item, selected: false }));
      },
    },
  },

  methods: {
    updateSort(event) {
      this.$emit('update:sort', event.sort);
    },

    updateFilter(event) {
      this.$emit('update:filter', event.filter);
    },

    updatePage(event) {
      this.page.skip = event.page.skip;
      this.page.take = event.page.take;
    },
    getActions(data) {
      return this.actions
        .filter((action) => typeof action.when === 'undefined' || action.when(data))
        .map((action) => ({ id: uniqueId(), ...action }));
    },
    getRoute(to, data) {
      if (typeof to === 'function') {
        return to(data);
      }

      return to;
    },
    selectAllRows() {
      if(this.selectable){
        this.internalItems.forEach(item => item.selected = true);
        this.emitSelect();
      }
    },
    clearSelected() {
      if(this.selectable){
        this.internalItems.forEach(item => item.selected = false);
        this.emitSelect();
      }
    },

    onRowClick (event) {
      if(this.selectable){
        Vue.set(event.dataItem, 'selected', !event.dataItem.selected);
        this.emitSelect();
      }
    },
    onHeaderSelectionChange(event) {
      let checked = event.event.target.checked;
      this.internalItems.forEach(item => item.selected = checked);
      this.emitSelect();
    },

    emitSelect(){
      this.$emit('select', this.internalItems.filter(item => item.selected));
    },
  },
};
</script>
