
export default {
  name: 'VLoadingButton',

  inheritAttrs: false,

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
