import { Module } from 'vuex';
import { State as RootState } from '.';

import { formatIso } from '@/utils/date';

import { umsatzsteuerartLaden, artikelAendernLaden, artikelinformationenFuerAendernLaden } from '@/api/bitwws';
import { Umsatzsteuerart } from '@/api/bitwws/umsatzsteuerartLaden';
import { ArtikelAendernLadenData } from '@/api/bitwws/artikelAendernLaden';
import { Response as ArtikelinformationenFuerAendernLadenResponse } from '@/api/bitwws/artikelinformationenFuerAendernLaden';
import { Informationsart } from '@/api/bitwws/enums/Informationsart';

interface VatType {
  id: string;
  description: string;
  active: boolean;
}

interface ArticleBasic {
  version: number;
  packageVersion: number;
  salesPackageVersion: number;
  id: string;
  packageId: string;
  salesPackageId: string;
  gtin: string;
  pluCode: number | null;
  pluCodeRange: number | null;
  name: string;
  vatTypeId: string;
  supplierId: string;
  assortmentId: string;
  mainPlacementId: string;
  productGroupId: string;
  shelfText: string;
  receiptText: string;
  packagingTypeId: string;
  contentsFactor: number;
  contentsQuantity: number;
  contentsUnitId: string;
  salesUnitType: number;
  depositArticleId: string | null;
  discountable: boolean | null;
  priceEntryRequired: boolean;
  hasSalesPackage: boolean;
  hasPurchaseUnit: boolean;
}

interface ProductInformation {
  version: number;
  productInfos: string | null;
  ingredientList: string | null;
  listingReason: string | null;
}

function mapVatTypeData(data: Umsatzsteuerart): VatType {
  return {
    id: data.Id,
    description: data.BezeichnungUndProzentWert,
    active: data.Aktiv,
  };
}

function mapArticleBasicData(data: ArtikelAendernLadenData): ArticleBasic {
  return {
    version: data.ArtikelRowVersion,
    packageVersion: data.VerpackungRowVersion,
    salesPackageVersion: data.VerkaufsverpackungRowVersion,
    id: data.ArtikelId,
    packageId: data.VerpackungId,
    salesPackageId: data.VerkaufsverpackungId,
    gtin: data.Gtin,
    pluCode: data.Plu,
    pluCodeRange: data.PluKreis,
    name: data.Artikelbezeichnung,
    vatTypeId: data.UmsatzsteuerartId,
    supplierId: data.LieferantId,
    assortmentId: data.SortimentId,
    mainPlacementId: data.HauptplatzierungId,
    productGroupId: data.WarengruppeId,
    shelfText: data.Regaltext,
    receiptText: data.Bontext,
    packagingTypeId: data.VerpackungsartId,
    contentsFactor: data.InhaltFaktor,
    contentsQuantity: data.InhaltMenge,
    contentsUnitId: data.InhaltEinheitId,
    salesUnitType: data.ArtDerVerkaufseinheit,
    depositArticleId: data.LeergutId,
    discountable: data.IstRabattfaehig ?? false,
    priceEntryRequired: data.PreiseingabeAnKasse ?? false,
    hasSalesPackage: data.HatVerkaufsverpackung,
    hasPurchaseUnit: data.HatBezugseinheit,
  };
}

function findProductInfo(
  data: ArtikelinformationenFuerAendernLadenResponse,
  type: Informationsart,
): string | null {
  return data.Artikelinformationen.find(i => i.Informationsart === type)?.Information ?? null;
}

function mapProductInfos(data: ArtikelinformationenFuerAendernLadenResponse): ProductInformation {
  return {
    version: data.ArtikelRowVersion,
    productInfos: findProductInfo(data, Informationsart.Produktinfo),
    ingredientList: findProductInfo(data, Informationsart.Zutatentext),
    listingReason: findProductInfo(data, Informationsart.Listungsgrund),
  };
}

interface State {
  vatTypes: VatType[] | null;
  articleBasic: ArticleBasic | null;
  productInfos: ProductInformation | null;
}

const storeModule: Module<State, RootState> = {
  namespaced: true,

  state: {
    vatTypes: null,
    articleBasic: null,
    productInfos: null,
  },

  getters: {
    availableVatTypes(state) {
      return state.vatTypes?.filter((option) => {
        return option.active || option.id === state.articleBasic?.vatTypeId;
      });
    },
  },

  mutations: {
    setVatTypes(state, vatTypes) {
      state.vatTypes = vatTypes;
    },

    setArticleBasic(state, articleBasic) {
      state.articleBasic = articleBasic;
    },

    setProductInfos(state, productInfos) {
      state.productInfos = productInfos;
    },
  },

  actions: {
    fetchVatTypes({ commit, state, rootState }) {
      if (state.vatTypes) {
        return Promise.resolve(state.vatTypes);
      }

      return umsatzsteuerartLaden({
        GueltigAb: formatIso(rootState.currentDate),
      }).then((response) => {
        commit('setVatTypes', response.Umsatzsteuerartdatas.map(mapVatTypeData));
      });
    },

    fetchArticleBasic({ commit, rootState, rootGetters }, id: string) {
      return artikelAendernLaden({
        ArtikelId: id,
        FilialId: rootGetters.currentStoreId,
        Stichtag: formatIso(rootState.currentDate),
      })
        .then((response) => {
          commit('setArticleBasic', mapArticleBasicData(response.ArtikelAendernLadenData));
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            commit('setArticleBasic', null);
          }
        });
    },

    fetchProductInfos({ commit, rootState }, id: string) {
      return artikelinformationenFuerAendernLaden({
        ArtikelId: id,
        Stichtag: formatIso(rootState.currentDate),
      })
        .then((response) => commit('setProductInfos', mapProductInfos(response)));
    },
  },
};

export default storeModule;
