import Vue from 'vue';
import PortalVue from 'portal-vue';
import Vuelidate from 'vuelidate';

Vue.use(PortalVue);
Vue.use(Vuelidate);

import './font-awesome';
import './kendo-ui-chat';
import './kendo-ui';
