import client from '@/api/client';
import { ArtikelverbundArt } from './enums/ArtikelverbundArt';


export interface Request {
  FilialeId: string;
  AenderungsIds: string[];
}

export interface Response {
  Verkaufspreisaenderungen: Verkaufspreisaenderung[];
}

export interface Verkaufspreisaenderung {
  Id: string;
  Artikelnummer: number;
  Setnummer: number;
  Bezeichnung: string;
  InhaltMenge: number;
  InhaltFaktor: number;
  InhaltEinheit: string;
  Bezuege: Bezug[];
  Umsatzsteuersatz: number;
  AlterPreis: number;
  NeuerPreis: number;
  GueltigAb: string;
  Bearbeitungsstatus: number;
  Filialverkaufspreis: number;
  ArtikelverbundArt?: ArtikelverbundArt;
  AnzahlTeile?: number | null;
  UnterartikelPreisaenderungen?: Verkaufspreisaenderung[];
}

export interface Bezug {
  LieferantenName: string;
  Bezugslistungsstatus: string;
  Bezugslistungsart: string;
  Lieferantenartikelnummer: string;
  Einkaufspreis: number;
  EinkaufspreisVortag: number;
  Zan: string;
  DisplayZan: string | null;
  Packeinheitsgroesse: number;
  Packeinheitseinheit: string;
  VerpackungBezeichnung: string | null;
  IstUnterartikelBezug: boolean;
}

export async function verkaufspreisaenderungenAnhandIdLaden(request: Request): Promise<Response> {
  return client.request('hof', 'VerkaufspreisaenderungenAnhandIdLaden', request)
    .then(response => response.data);
}
