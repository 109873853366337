<template>
  <div class="radio-box">
    <div
      v-for="option in options"
      :key="option.id"
      class="radio-box-item"
    >
      <input
        :id="`${id}-${option.id}`"
        v-model="internalValue"
        :name="id"
        :value="option.id"
        :required="required"
        type="radio"
      >

      <label :for="`${id}-${option.id}`">
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';

export default {
  name: 'VRadioBox',

  props: {
    id: {
      type: String,
      default: () => uniqueId(),
    },

    required: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      default: () => [],
    },

    value: {
      type: [Boolean, Number, String, Object],
      default: null,
    },
  },

  data() {
    return {
      internalValue: this.value,
    };
  },

  watch: {
    value(newValue) {
      this.internalValue = newValue;
    },

    internalValue(newInternalValue) {
      this.$emit('input', newInternalValue);
    },
  },
};
</script>
