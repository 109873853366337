import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  GueltigVon: string;
  FilialeId: UUID;
  Preise: Preis[];
}

export interface Response {
  PreisMassenpflegeErgebnisse: PreisMassenpflegeErgebnis[];
}

export interface Preis {
  ArtikelId: UUID;
  BezugseinheitId: UUID;
  Einkaufspreis: number | string;
  Verkaufspreis: number | string;
  Referenzkey: string;
  IstUnterartikel: boolean;
}

export interface PreisMassenpflegeErgebnis {
  ArtikelId: UUID;
  BezugseinheitId: UUID;
  Angelegt: boolean;
  Fehlermeldung: string;
  Referenzkey: string;
}

export async function preisMassenpflege(request: Request): Promise<Response> {
  return client.request('hof', 'PreisMassenpflege', request)
    .then(response => response.data);
}
