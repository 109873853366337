<template>
  <div class="page-filters">
    <v-collapse :value="show">
      <div class="page-filters-content">
        <slot />
      </div>
    </v-collapse>
  </div>
</template>

<script>
export default {
  name: 'VPageFilters',

  inheritAttrs: false,

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
