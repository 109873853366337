import client from '@/api/client';
import { ArtikelverbundArt } from './enums/ArtikelverbundArt';
import { UUID } from './types';

export interface Request {
  FilialeId: string;
  ArtikelIds: string[];
}

export interface Response {
  Artikel: Artikel[];
}

export interface Artikel {
  Id: string;
  Setnummer: number;
  Bezeichnung: string;
  Inhalt: string;
  Umsatzsteuersatz: number;
  Filialverkaufspreis: number;
  Zentralverkaufspreis: number | null;
  Bezugseinheiten: Bezugseinheit[];
  Unterartikel?: Artikel[];
  ArtikelverbundArt?: ArtikelverbundArt;
  AnzahlTeile?: number | null;
}

export interface Bezugseinheit {
  BezugseinheitId: UUID;
  LieferantenName: string;
  Bezugslistungsstatus: string;
  Bezugslistungsart: string;
  Lieferantenartikelnummer: string;
  Einkaufspreis: number;
  EinkaufspreisVortag: number;
  Zan: string;
  DisplayZan: string | null;
  Packeinheitsgroesse: number;
  Packeinheitseinheit: string;
  VerpackungBezeichnung: string | null;
  IstZentralerBezug: boolean;
  IstUnterartikelBezug: boolean;
  IstEkAenderbar: boolean;
  EkNichtAenderbarGrund: string | null;
}

export async function artikelFuerVerkaufspreisaenderungLaden(request: Request): Promise<Response> {
  return client.request('hof', 'ArtikelFuerVerkaufspreisaenderungLaden', request)
    .then(response => response.data);
}
