import client from '@/api/client';

export interface Request {
  ArtikelId: string;
}

export interface Response {
  Spezialattributsgruppen: Spezialattributsgruppe[];
  NaehrwerteAnzeigeData: null;
}

export interface Spezialattributsgruppe {
  Bezeichnung: string;
  Spezialattribute: Spezialattribut[];
}

export interface Spezialattribut {
  Bezeichnung: string;
  Kennzeichen: string;
  IstPflichtfeld: boolean;
  Wert: unknown | null;
  WertKennzeichen?: string | null;
  EinheitId: string | null;
}

export async function spezialattributeEinesArtikelsLaden(request: Request): Promise<Response> {
  return client.request('art', 'SpezialattributeEinesArtikelsLaden', request)
    .then(response => response.data);
}
