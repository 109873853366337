import client from '@/api/client';

export interface Request {
  Suchtext: string;
  HauptGtin: string;
  SortimentId: string | null;
  WarengruppeId: string | null;
  HauptplazierungId: string | null;
  LieferantId: string | null;
  FilialeId: string;
  HerstellerId: string | null;
  SucheNurEigeneArtikel: boolean;
  SucheNurVerkaufsartikel: boolean;
  Stichtag: string;
  MaximaleAnzahlTreffer: number;
  NurAktiveArtikel: boolean;
  NurGesperrteArtikel: boolean;
  NurGelisteteArtikel: boolean;
  Lieferantenartikelnummern: string | null;
}

export interface Response {
  ArtikelIds: string[];
}

export async function artikelSuchen(request: Request): Promise<Response> {
  return client.request('art', 'ArtikelSuchen', request)
    .then(response => response.data);
}
