import client from '@/api/client';

export interface Request {
  SortimentId: string | null;
  ArtikelId?: string;
}

export interface Response {
  SpezialattributGruppen: SpezialattributGruppe[];
}

export interface SpezialattributGruppe {
  Id: string;
  Bezeichnung: string;
  Spezialattribute: Spezialattribut[];
}

export interface Spezialattribut {
  Kennzeichen: string;
  Bezeichnung: string;
  SpezialattributTyp: string;
  IstPflichtfeld: boolean;
  ErlaubtMehrfachauswahl: boolean;
  Feldlaenge?: number | null;
  Dezimalstellen?: number | null;
  MinimalWert?: number | null;
  MaximalWert?: number | null;
  Werte: Wert[] | null;
}

export interface Wert {
  Kennzeichen: string;
  Bezeichnung: string;
  Einheit?: string;
  IstPflichtfeld?: boolean;
}

export async function spezialattributeLaden(request: Request): Promise<Response> {
  return client.request('art', 'SpezialattributeLaden', request)
    .then(response => response.data);
}
