
import { BInputGroup } from 'bootstrap-vue';

export default {
  name: 'VInputGroup',

  components: {
    BInputGroup,
  },

  inheritAttrs: false,
};
