
import Vue from 'vue';

export default Vue.extend({
  name: 'VPageBarMenu',

  computed: {
    hasDefaultSlot(): boolean {
      return this.$slots['default'] !== undefined;
    },

    hasSecondarySlot(): boolean {
      return this.$slots['secondary'] !== undefined;
    },
  },
});
