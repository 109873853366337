
import Vue from 'vue';

export default Vue.extend({
  name: 'VBulkSelect',

  props: {
    itemCount: {
      type: Number,
      required: true,
    },

    selectionCount: {
      type: Number,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    allSelected(): boolean {
      return this.itemCount > 0 && this.selectionCount === this.itemCount;
    },

    anySelected(): boolean {
      return this.selectionCount > 0;
    },

    isDisabled() {
      return this.disabled || this.itemCount === 0;
    },
  },

  methods: {
    selectAll(event: Event) {
      this.$emit('select-all', event);
    },

    selectNone(event: Event) {
      this.$emit('select-none', event);
    },
  },
});
