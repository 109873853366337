<template>
  <b-form-input
    :disabled="finalDisabled"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { BFormInput } from 'bootstrap-vue';
import disabledChild from '@/mixins/disabled-child';

export default {
  name: 'VInput',

  components: {
    BFormInput,
  },

  mixins: [disabledChild],

  inheritAttrs: false,
};
</script>
