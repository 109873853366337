import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  ArtikelId: UUID;
  FilialeId: UUID;
}

export interface Response {
  AktuellePreisdaten: AktuellePreisdaten;
  Preishistorie: PreishistorieEntry[];
}

export interface AktuellePreisdaten {
  Verkaufspreis: number;
  Marktverkaufspreis: number | null;
  ZentralerVerkaufspreis: number | null;
  AbweichendeEkps: boolean;
  LieferantenMitEkp: LieferantMitEkp[];
}

export interface LieferantMitEkp {
  LieferantId: UUID;
  VerpackungBezeichnung: string | null;
  Bezeichnung: string;
  Wert: number;
  Zan: string;
  IstUnterartikelBezug: boolean;
  DisplayZan: string | null;
  Packeinheitsgroesse: number;
  Packeinheitseinheit: string;
  Lieferantenartikelnummer: string;
  Bezugslistungsstatus: string;
  Bezugslistungsart: string;
}

export interface PreishistorieEntry {
  GueltigVon: string;
  GueltigBis: string;
  Verkaufspreis: number;
  Marktverkaufspreis: number | null;
  ZentralerVerkaufspreis: number;
}

export async function preishistorieLaden(request: Request): Promise<Response> {
  return client.request('hof', 'PreishistorieLaden', request)
    .then(response => response.data);
}
