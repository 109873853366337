import client from '@/api/client';
import { ArtikelverbundArt } from './enums/ArtikelverbundArt';

export interface Request {
  FilialeId: string;
  Stichtag: string;
  ArtikelIds: string[];
}

export interface Response {
  ArtikelDatas: Artikel[];
}

export interface Artikel {
  Setnummer: number;
  Artikelnummer: number;
  Bezeichnung: string;
  Inhalt: string;
  HauptGtin: string;
  Sortiment: string;
  Warengruppe: string;
  Hauptplatzierung: string;
  Verkaufspreis: string | null;
  Einkaufspreis: string | null;
  IstDezentralerArtikel: boolean;
  IstVerkaufsartikel: boolean;
  IstGelistet: boolean;
  RelevanterVerkaufspreis: number | null;
  Bezuege: Bezug[];
  ArtikelverbundArt: ArtikelverbundArt;
  ZentralerVerkaufspreis: number | null;
  BesitztAktiveLagerlistung: boolean;
}

export interface Bezug {
  Lieferant: string;
  Bezugslistungsstatus: string;
  Bezugslistungsart: string;
  Lieferantenartikelnummer: string;
  Einkaufspreis: number | null;
  Zan: string;
  Packeinheitsgroesse: number;
  Packeinheitseinheit: null;
  VerpackungBezeichnung: null;
}

export async function artikelFuerSucheLaden(request: Request): Promise<Response> {
  return client.request('hof', 'ArtikelFuerSucheLaden', request)
    .then(response => response.data);
}
