<template>
  <b-form-radio
    :class="{ 'checkbox-form-control': formField }"
    :disabled="finalDisabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </b-form-radio>
</template>

<script>
import { BFormRadio } from 'bootstrap-vue';
import disabledChild from '@/mixins/disabled-child';

export default {
  name: 'VRadiobox',

  components: {
    BFormRadio,
  },

  mixins: [disabledChild],

  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    formField: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
