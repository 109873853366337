import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  Artikelbezeichnung: string;
  WarengruppeId: UUID;
  LieferantId: UUID;
  Stichtag: string;
  FilialeId: UUID;
}

export interface Response {
  LeergutArtikelLadenDatas: LeergutArtikelLadenData[];
}

export interface LeergutArtikelLadenData {
  LeergutId: string;
  Bezeichnung: string;
  Pfandwert: number;
  Verwendungsart: Verwendungsart;
}

export enum Verwendungsart {
  Artikelleergut = 'Artikelleergut',
}

export async function leergutArtikelLaden(request: Request): Promise<Response> {
  return client.request('art', 'LeergutArtikelLaden', request)
    .then(response => response.data);
}
