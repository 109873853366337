
import Vue from 'vue';
import { BNavbarNav } from 'bootstrap-vue';

export default Vue.extend({
  name: 'VNavbarNav',

  components: {
    BNavbarNav,
  },

  inheritAttrs: false,
});
