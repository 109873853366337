<template>
  <div
    class="page-bar"
    :style="styles"
  >
    <div class="page-bar-content">
      <slot />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'VPageBar',

  inject: {
    page: {
      default: null,
    },
  },

  computed: {
    styles() {
      if (!this.page) {
        return {};
      }

      return {
        'padding-right': `${this.page.scrollbarOffset}px`,
      };
    },
  },
});
</script>
