
import { BInputGroupPrepend } from 'bootstrap-vue';

export default {
  name: 'VInputGroupPrepend',

  components: {
    BInputGroupPrepend,
  },

  inheritAttrs: false,
};
