<template>
  <div
    v-if="active"
    :id="id"
    class="wizard-panel"
  >
    <slot :form-id="formId" />
  </div>
</template>

<script>
export default {
  name: 'VWizardStep',

  inject: ['wizard'],

  props: {
    id: {
      type: [String, Number],
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    isFinishedStep: {
      type: Boolean,
      default: false,
    },

    formId: {
      type: String,
      default: null,
    },

    nextButtonText: {
      type: String,
      default: null,
    },

    enableNext: {
      type: Boolean,
      default: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    show: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    active() {
      return this.wizard.activeStep === this;
    },
  },

  created() {
    this.wizard.addStep(this);
  },

  beforeDestroy() {
    this.wizard.removeStep(this);
  },
};
</script>
