
import Vue from 'vue';
import { BSpinner } from 'bootstrap-vue';

export default Vue.extend({
  name: 'VSpinner',

  components: {
    BSpinner,
  },

  inheritAttrs: false,
});
