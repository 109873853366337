import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  MischgebindeAendernData: MischgebindeAendernData;
  FilialeId: UUID;
  GueltigVon: string;
  GueltigBis: string;
}

export interface MischgebindeAendernData {
  ArtikelId: UUID;
  ArtikelRowVersion: number;
  GesamtAnzahlTeile: number;
  ArtikelverbundbestandteilDatas: ArtikelverbundbestandteilData[];
  EinkaufspreisAendernDatas: EinkaufspreisAendernData[];
}

export interface ArtikelverbundbestandteilData {
  ArtikelId: UUID;
  Anzahl: number;
  EinkaufspreisAendernDatas: EinkaufspreisAendernData[];
}

export interface EinkaufspreisAendernData {
  BezugseinheitId: UUID;
  Einkaufspreis: number;
}

export async function mischgebindeAendern(request: Request): Promise<null> {
  return client.request('art', 'MischgebindeAendern', request)
    .then(response => response.data);
}
