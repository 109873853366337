import { Module } from 'vuex';
import { State as RootState } from '.';
import LocalStorage from '@/utils/local-storage';

interface State {
  sidebarAlwaysOpened: boolean;
}

const storeModule: Module<State, RootState> = {
  namespaced: true,

  state: {
    sidebarAlwaysOpened: LocalStorage.getJsonOrDefault('sidebar-always-opened', false),
  },

  mutations: {
    setSidebarAlwaysOpened(state, sidebarAlwaysOpened: boolean) {
      state.sidebarAlwaysOpened = sidebarAlwaysOpened;
      LocalStorage.setJson('sidebar-always-opened', state.sidebarAlwaysOpened);
    },
  },
};

export default storeModule;
