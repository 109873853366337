import Vue from 'vue';
import { Store } from 'vuex';
import VueRouter from 'vue-router';
import { AxiosResponse } from 'axios';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import '@/plugins';
import '@/register-components';

import client from '@/api/client';
import server from '@/api/server';

const createMaintenanceErrorHandler = (router: VueRouter, store: Store<unknown>) => {
  return (response: AxiosResponse) => {
    store.commit('systemConfig/setMaintenance', response.data.maintenance);

    router.replace('/maintenance');
  };
};

client.authorizeRefresh(() => {
  store.commit('removeUser');
  router.push('/login');
});

client.registerErrorHandler([503], createMaintenanceErrorHandler(router, store));
server.registerErrorHandler([503], createMaintenanceErrorHandler(router, store));

Vue.config.productionTip = false;

store.dispatch('initialize').finally(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');
});
