
import { BAlert } from 'bootstrap-vue';

export default {
  name: 'VAlert',

  components: {
    BAlert,
  },

  inheritAttrs: false,
};
