<template>
  <div
    role="combobox"
    aria-haspopup="listbox"
    :aria-controls="`${id}-list`"
    :aria-expanded="open ? 'true' : 'false'"
    class="combobox"
    :class="{ 'open': isOpen }"
  >
    <input
      :value="value"
      type="text"
      class="form-control"
      aria-autocomplete="list"
      :aria-activedescendant="`${id}-option-${focusedIndex}`"
      :required="required"
      @input="onInput($event)"
      @keydown.up.prevent="focusPreviousOption()"
      @keydown.down.prevent="focusNextOption()"
      @keydown.enter="onEnter($event)"
      @blur.prevent="closeList()"
      @click.prevent="openList()"
      @keyup.esc.prevent="closeList()"
    >

    <ul
      :id="`${id}-list`"
      ref="list"
      role="listbox"
      class="combobox-list"
      @mousedown.prevent
    >
      <li
        v-for="(option, index) in options"
        :id="`${id}-option-${index}`"
        :key="index"
        role="option"
        :class="{ 'focus': focusedIndex === index }"
        @click="selectOption(index)"
      >
        <slot
          name="list-option"
          :option="option"
        >
          {{ option }}
        </slot>
      </li>
    </ul>
  </div>
</template>

<script>
import { uniqueId } from 'lodash';

export default {
  name: 'VCombobox',

  props: {
    id: {
      type: String,
      default: () => uniqueId('v-combobox-'),
    },

    value: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    required: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      open: false,
      focusedIndex: -1,
    };
  },

  computed: {
    isOpen() {
      return this.open && this.options.length > 0;
    },
  },

  watch: {
    value(newValue) {
      this.$emit('search', newValue);
    },

    focusedIndex() {
      this.$nextTick(() => this.adjustScrollPosition());
    },

    options() {
      this.resetFocusedIndex();
    },
  },

  methods: {
    openList() {
      this.open = true;
    },

    closeList() {
      this.open = false;

      this.resetFocusedIndex();
    },

    selectOption(index) {
      if (index === -1) {
        return;
      }

      const option = this.options[index] ?? null;

      this.$emit('select-option', option);

      this.closeList();
    },

    focusNextOption() {
      const index = this.focusedIndex + 1;

      if (index < this.options.length) {
        this.focusedIndex = index;
      }

      this.openList();
    },

    focusPreviousOption() {
      const index = this.focusedIndex - 1;

      if (index >= 0) {
        this.focusedIndex = index;
      }

      this.openList();
    },

    resetFocusedIndex() {
      this.focusedIndex = -1;
    },

    onInput(event) {
      this.openList();

      this.$emit('input', event.target.value);
    },

    onEnter(event) {
      if (this.isOpen) {
        this.selectOption(this.focusedIndex);

        event.preventDefault();
      }
    },

    adjustScrollPosition() {
      const list = this.$refs.list;
      const item = list.querySelectorAll('li')[this.focusedIndex];

      if (!item) {
        return;
      }

      const visMin = list.scrollTop;
      const visMax = list.scrollTop + list.clientHeight - item.clientHeight;

      if (item.offsetTop < visMin) {
        list.scrollTop = item.offsetTop;
      } else if (item.offsetTop >= visMax) {
        list.scrollTop = (item.offsetTop - list.clientHeight + item.clientHeight);
      }
    },
  },
};
</script>
