import { AttributeOptions, AttributeGroup, Attribute } from './attribute';
import { AttibuteTypeConfig, AttributeConfig } from './config';
import { Spezialattribut, SpezialattributGruppe } from '@/api/bitwws/spezialattributeLaden';
import { Spezialattributsgruppe as ArtikelLadenSpezialattributsgruppe } from '@/api/bitwws/spezialattributeEinesArtikelsLaden';
import { Spezialattribut as ArtikelAnlegenSpezialattribut } from '@/api/bitwws/artikelAnlegen';
import { SpezialattributAendernData as ArtikelAendernSpezialattribut } from '@/api/bitwws/spezialattributeEinesArtikelsAendern';

type ApiAttributeData = ArtikelAnlegenSpezialattribut | ArtikelAendernSpezialattribut;

export class AttributeService {
  constructor(
    private typeConfig: Array<AttibuteTypeConfig>,
    private config: Record<string, AttributeConfig>,
  ) {}

  public buildAttributeGroups(apiAttributeGroups: SpezialattributGruppe[]): AttributeGroup[] {
    return apiAttributeGroups.filter((group) => group.Id !== '33477108-d279-4195-9c13-8caa35a2c528')
      .map((apiAttributeGroup) => {
        return {
          id: apiAttributeGroup.Id,
          label: apiAttributeGroup.Bezeichnung,
          attributes: apiAttributeGroup.Spezialattribute.map((apiAttribute) => {
            return this.buildAttribute(apiAttribute);
          }),
        };
      });
  }

  public buildAttribute(apiAttribute: Spezialattribut): Attribute {
    const typeConfig = this.getConfigByApiType(apiAttribute.SpezialattributTyp);

    return {
      type: typeConfig.type,
      id: apiAttribute.Kennzeichen,
      label: apiAttribute.Bezeichnung,
      required: apiAttribute.IstPflichtfeld,
      options: this.getOptions(typeConfig, apiAttribute),
      value: this.getDefaultValue(typeConfig, apiAttribute),
      defaultValue: this.getDefaultValue(typeConfig, apiAttribute),
      when: this.config[apiAttribute.Kennzeichen]?.when,
      isEmpty: typeConfig.isEmpty,
    };
  }

  public buildApiAttributes(attributeGroups: AttributeGroup[]): ApiAttributeData[] {
    return attributeGroups.flatMap(group => group.attributes)
      .map((attribute) => {
        const config = this.getConfigByType(attribute.type);

        return {
          SpezialattributTyp: config.apiType,
          SpezialattributKuerzel: attribute.id,
          ...config.sendTransformer(attribute),
        };
      });
  }

  public setAttributeGroupsData(
    attributeGroups: AttributeGroup[],
    apiAttributeGroups: ArtikelLadenSpezialattributsgruppe[],
  ): void {
    apiAttributeGroups.flatMap((apiAttributeGroup) => apiAttributeGroup.Spezialattribute)
      .forEach((apiAttribute) => {
        const attribute = this.findAttribute(attributeGroups, apiAttribute.Kennzeichen);

        if (attribute) {
          const config = this.getConfigByType(attribute.type);
          attribute.value = config.readTransformer(apiAttribute);
        }
      });
  }

  public cleanAttributes(attributeGroups: AttributeGroup[]): void {
    attributeGroups.flatMap(group => group.attributes).forEach((attribute, _, attributes) => {
      if (attribute.when && !attribute.when(attributes)) {
        attribute.value = attribute.defaultValue;
      }
    });
  }

  private findAttribute(attributeGroups: AttributeGroup[], id: string): Attribute | undefined {
    return attributeGroups.flatMap((group) => group.attributes)
      .find((attribute) => attribute.id === id);
  }

  private getOptions(
    config: AttibuteTypeConfig,
    apiAttribute: Spezialattribut,
  ): AttributeOptions | undefined {
    return config.optionBuilder && config.optionBuilder(apiAttribute);
  }

  private getDefaultValue(config: AttibuteTypeConfig, apiAttribute: Spezialattribut): unknown {
    return config.defaultValue(apiAttribute);
  }

  private getConfigByApiType(apiType: string): AttibuteTypeConfig {
    const config = this.typeConfig.find(config => config.apiType === apiType);

    if (!config) {
      throw new Error(`No config found for API type ${apiType}.`);
    }

    return config;
  }

  private getConfigByType(type: string): AttibuteTypeConfig {
    const config = this.typeConfig.find(config => config.type === type);

    if (!config) {
      throw new Error(`No config found for API type ${type}.`);
    }

    return config;
  }
}
