import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  FilialeId: UUID;
  LiefereinheitId: UUID;
  RowVersion: number;
  Stichtag: string;
  LieferantenartikelnummerGeandert: boolean;
  Lieferantenartikelnummer: number;
}

export async function bezugseinheitAendern(request: Request): Promise<null> {
  return client.request('art', 'HofBezugseinheitAendern', request)
    .then(response => response.data);
}
