import { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { hasSomePermission } from '@/utils/permissions';

const trailingSlashRE = /\/?$/;

function queryIncludes(
  current: Dictionary<string | (string | null)[]>,
  target: Dictionary<string | (string | null)[]>,
): boolean {
  for (const key in target) {
    if (!(key in current)) {
      return false;
    }
  }

  return true;
}

export function isIncludedRoute(current: Route, target: Route): boolean {
  return (
    current.path.replace(trailingSlashRE, '/').indexOf(
      target.path.replace(trailingSlashRE, '/'),
    ) === 0 &&
    (!target.hash || current.hash === target.hash) &&
    queryIncludes(current.query, target.query)
  );
}

export function isRouteAllowed(to: Route): boolean {
  const requiredPermissions: string[] = [];

  to.matched.forEach((record) => {
    if (Array.isArray(record.meta.permissions)) {
      requiredPermissions.push(...record.meta.permissions);
    }
  });

  if (requiredPermissions.length === 0) {
    return true;
  }

  return hasSomePermission(requiredPermissions);
}
