import client from '@/api/client';
import { UUID } from '@/api/bitwws/types';
import { Informationsart } from '@/api/bitwws/enums/Informationsart';

export interface Request {
  ArtikelId: UUID;
  Stichtag: string;
}

export interface Response {
  ArtikelRowVersion: number;
  Artikelinformationen: Artikelinformation[];
}

export interface Artikelinformation {
  Informationsart: Informationsart;
  Information: string;
}

export async function artikelinformationenFuerAendernLaden(request: Request): Promise<Response> {
  return client.request('art', 'ArtikelinformationenFuerAendernLaden', request)
    .then(response => response.data);
}
