import Axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import TokenRefresher from './token-refresher';
import loginService from './login-service';

interface Module {
  url: string;
}

interface ModuleList {
  [key: string]: Module;
}

interface Config {
  baseUrl?: string;
}

interface ErrorHandler {
  (response: AxiosResponse): void;
}

function createRefreshTokenInterceptor(client: AxiosInstance, errorCallback: () => void): void {
  const tokenHandler = new TokenRefresher();

  client.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    const { config, response } = error;
    const originalRequest = config;
    const resource = originalRequest.url.replace(originalRequest.baseURL, '');

    if (!response) {
      return Promise.reject(error);
    }

    if (tokenHandler.isWhitelisted(resource)) {
      return Promise.reject(error);
    }

    if (response.status === 401) {
      if (!tokenHandler.isRefreshing) {
        tokenHandler.fireRefreshTokenRequest().catch(() => {
          return Promise.reject(error);
        });
      }

      return new Promise((resolve, reject) => {
        tokenHandler.subscribe((newToken: string) => {
          // replace the expired token and retry
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          originalRequest.url = originalRequest.url.replace(originalRequest.baseURL, '');
          resolve(Axios(originalRequest));
        }, (error) => {
          errorCallback();
          reject(error);
        });
      });
    }

    return Promise.reject(error);
  });
}

function createGlobalErrorHandler(client: AxiosInstance, errorCallback: (error: unknown) => void): void {
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      const { response } = error;

      if (!response || response.status === 500) {
        errorCallback(error);
      }

      return Promise.reject(error);
    },
  );
}

const modules: ModuleList = {
  hof: {
    url: '/BIT.hof/api/HofRequestExecute',
  },
  art: {
    url: '/BIT.artHofApi/api/HofRequestExecute',
  },
  org: {
    url: '/BIT.OrgApi/api/OrgRequestExecute',
  },
  lua: {
    url: 'BIT.LuaApi/api/LuaRequestExecute',
  },
  sec: {
    url: '/BIT.SecApi/api/SecRequestExecute',
  },
  wws: {
    url: '/BIT.wwsApi/api/ApplikationsverwaltungRequestExecute',
  },
};

export class Client {
  private httpClient: AxiosInstance;

  constructor(config: Config) {
    this.httpClient = Axios.create({
      baseURL: config.baseUrl,
    });
  }

  public authorizeRefresh(errorCallback: () => void): void {
    createRefreshTokenInterceptor(this.httpClient, errorCallback);
  }

  public errorHandler(errorCallback: () => void): void {
    createGlobalErrorHandler(this.httpClient, errorCallback);
  }

  public registerErrorHandler(statusCodes: number[], errorHandler: ErrorHandler) {
    this.httpClient.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        const { response } = error;

        if (response && statusCodes.includes(response.status)) {
          return errorHandler(response);
        }

        return Promise.reject(error);
      },
    );
  }

  public async post(url: string, data?: object) {
    return this.httpClient.post(url, data, { headers: this.getHeaders() });
  }

  public async request(module: string, contract: string, data: object) {
    const moduleUrl = modules[module].url;

    return this.post(moduleUrl, { Contractname: contract, RequestJson: data });
  }

  private getHeaders() {
    const headers: { [key: string]: string } = {};

    const token = loginService.getToken();

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return headers;
  }
}

export default new Client({
  baseUrl: process.env.VUE_APP_BITWWS_URL,
});
