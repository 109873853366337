
import Vue from 'vue';
import { BNav } from 'bootstrap-vue';

export default Vue.extend({
  name: 'VNav',
  components: {
    BNav,
  },
  inheritAttrs: false,
});
