
import Vue, { PropType } from 'vue';
import { Route } from 'vue-router';
import { isIncludedRoute, isRouteAllowed } from '@/utils/route';
import { Item, ChildItem } from '@/config/navigation';

export default Vue.extend({
  name: 'VSidebarNav',

  props: {
    items: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
  },

  computed: {
    currentRoute(): Route {
      return this.$route;
    },
  },

  methods: {
    hasChildren(item: Item): boolean {
      return Array.isArray(item.children) && item.children.length > 0;
    },

    isCurrent(item: Item): boolean {
      const items = [item, ...(item.children ?? [])];

      return items.some(item => isIncludedRoute(this.currentRoute, this.resolveRoute(item)));
    },

    hasPermissions(item: Item): boolean {
      return isRouteAllowed(this.resolveRoute(item));
    },

    resolveRoute(item: Item|ChildItem): Route {
      const { route } = this.$router.resolve(item.route);

      return route;
    },
  },
});
