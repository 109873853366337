
import { BInputGroupText } from 'bootstrap-vue';

export default {
  name: 'VInputGroupText',

  components: {
    BInputGroupText,
  },

  inheritAttrs: false,
};
