<template>
  <div class="app-loading">
    <v-spinner />
  </div>
</template>

<script>
export default {
  name: 'AppLoading',
};
</script>
