<template functional>
  <div
    :id="id"
    class="page-content-loading"
    role="progressbar"
  >
    <v-spinner />
  </div>
</template>

<script>
export default {
  name: 'VPageContentLoading',

  props: {
    id: {
      type: String,
      default: null,
    },
  },
};
</script>
