<template>
  <div
    class="page-footer"
    :style="styles"
  >
    <div class="page-footer-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VPageFooter',

  inject: {
    page: {
      default: null,
    },
  },

  computed: {
    styles() {
      if (!this.page) {
        return {};
      }

      return {
        'padding-right': `${this.page.scrollbarOffset}px`,
      };
    },
  },
};
</script>
