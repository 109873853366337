import client from '@/api/client';

export interface Response {
  Kommunikationsarten: Kommunikationsart[];
}

export interface Kommunikationsart {
  Bezeichnung: string;
  Vorlagen: string[];
}

export async function kommunikationsartenLaden(): Promise<Response> {
  return client.request('org', 'KommunikationsartenLaden', {})
    .then(response => response.data);
}
