import Vue from 'vue';
import { Module } from 'vuex';
import { State as RootState } from '.';

import server from '@/api/server';

interface State {
  config: { [key: string]: unknown };
}

const storeModule: Module<State, RootState> = {
  namespaced: true,

  state: {
    config: {},
  },

  mutations: {
    setConfig(state, config) {
      state.config = config;
    },

    setMaintenance(state, maintenance) {
      Vue.set(state.config, 'maintenance', maintenance);
    },
  },

  actions: {
    async fetchConfig({ commit }) {
      return server.getConfig()
        .then((response) => {
          commit('setConfig', response.data);
        });
    },
  },
};

export default storeModule;
