<template>
  <div
    class="app-shell"
    :class="{ 'sidebar-always-open': sidebarAlwaysOpened }"
  >
    <v-navbar>
      <v-navbar-sidebar-toggler
        :opened="showSidebar"
        @click.stop="toggleSidebar()"
      />

      <v-navbar-title>
        {{ title }}
      </v-navbar-title>

      <v-navbar-nav class="ml-auto">
        <v-nav-item-dropdown
          v-if="stores.length"
          class="store-selector"
          right
        >
          <template #button-content>
            <v-icon icon="store-alt" />
            {{ currentFiliale ? currentFiliale.Kuerzel : '' }}
          </template>

          <v-dropdown-item
            v-for="(store, index) in sortedStores"
            :key="`${store.Id}-${index}`"
            @click="selectStore(store)"
          >
            {{ store.Kuerzel }}
          </v-dropdown-item>
        </v-nav-item-dropdown>

        <v-nav-item-dropdown right>
          <template #button-content>
            <v-icon icon="user" />
            {{ user.name }}
          </template>

          <v-dropdown-item @click="logout()">
            {{ $t('components.default.logout') }}
          </v-dropdown-item>
        </v-nav-item-dropdown>

        <v-nav-item-dropdown right>
          <template #button-content>
            <v-icon
              icon="question-circle"
              :title="$t('components.default.help')"
            />
            <span class="sr-only">{{ $t('components.default.help') }}</span>
          </template>

          <v-dropdown-item :to="{ name: 'about-legal-notice' }">
            {{ $t('components.default.legal_notice') }}
          </v-dropdown-item>
        </v-nav-item-dropdown>
      </v-navbar-nav>
    </v-navbar>

    <v-sidebar v-model="showSidebar">
      <v-sidebar-nav :items="navigationItems" />
    </v-sidebar>

    <main class="main">
      <router-view
        v-if="stores.length > 0"
        :key="$route.path"
      />
      <no-stores v-else-if="ready" />
      <app-loading v-else />
    </main>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import server from '@/api/server';
import navigationItems from '@/config/navigation';
import AppLoading from '@/components/app/AppLoading.vue';
import NoStores from '@/components/app/NoStores.vue';

export default {
  name: 'AppShell',

  components: {
    AppLoading,
    NoStores,
  },

  data() {
    return {
      navigationItems,
      showSidebar: false,
      title: process.env.VUE_APP_TITLE,
      ready: false,
    };
  },

  computed: {
    ...mapState(['user', 'currentFiliale', 'stores', 'permissions']),
    ...mapState('ui', ['sidebarAlwaysOpened']),

    sortedStores() {
      return this.stores.slice().sort((a, b) => {
        if (a.Kuerzel < b.Kuerzel) {
          return -1;
        }

        if (a.Kuerzel > b.Kuerzel) {
          return 1;
        }

        return 0;
      });
    },
  },

  created() {
    this.$store.dispatch('fetchStores').then(() => {
      this.ready = true;
    });
  },

  methods: {
    ...mapMutations(['setCurrentFiliale']),

    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },

    selectStore(store) {
      this.setCurrentFiliale(store);

      if (this.$router.currentRoute.name !== 'article-list') {
        this.$router.push({ name: 'article-list' });
      }
    },

    logout() {
      server.logout();
      this.$router.push({ name: 'login' });
      this.$store.commit('removeUser');
    },
  },
};
</script>
