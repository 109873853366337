import client from '@/api/client';
import { UUID } from './types';
import { Hauptkommunikationsart } from './enums/Hauptkommunikationsart';

export interface Request {
  UnternehmenGrunddaten: {
    Firma: string;
    Kurzname: string;
    Geschaeftsbereich: string;
    HausinterneGlnVergeben: boolean;
    Gln: string;
    UmsatzsteuerIdentnummer: string;
    Steuernummer: string;
    Verpackungsregistrierungsnummer: string;
  };
  Zustellangabe: Zustellangabe | null;
  Abholangabe: Abholangabe | null;
  Hauptkommunikationsart: Hauptkommunikationsart;
  Kommunikationsdaten: Kommunikationsdaten[];
}

export interface Zustellangabe {
  Zustelltext: string;
  Strasse: string;
  Hausnummer: string;
  Plz: string;
  Ort: string;
  Nation: UUID;
}

export interface Abholangabe {
  Postfach: string;
  Plz: string;
  Ort: string;
  Nation: UUID;
}

export interface Kommunikationsdaten {
  Art: string;
  Bezeichnung: string;
  Wert: string;
}

export interface Response {
  UnternehmenId: UUID;
}

export async function unternehmenAnlegen(request: Request): Promise<Response> {
  return client.request('org', 'UnternehmenAnlegen', request)
    .then(response => response.data);
}
