import client from '@/api/client';
import { UUID } from '@/api/bitwws/types';

export interface Request {
  OrtsName: string;
  Plz: string;
}

export interface Response {
  Orte: Ort[];
}

export interface Ort {
  Name: string;
  Postleitzahl: string;
  NationId: UUID;
}

export async function orteLaden(request: Request): Promise<Response> {
  return client.request('org', 'OrteLaden', request)
    .then(response => response.data);
}
