<template>
  <div
    class="sidebar"
    :class="{ open: opened, 'always-open': alwaysOpened }"
  >
    <slot />

    <v-button
      variant="light"
      squared
      @click="alwaysOpened = !alwaysOpened"
    >
      <v-icon :icon="alwaysOpened ? 'angle-double-left' : 'angle-double-right'" />

      {{ alwaysOpened ? $t('components.sidebar.hide') : $t('components.sidebar.show_always') }}
    </v-button>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'VSidebar',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      opened: this.value,
    };
  },

  computed: {
    ...mapState('ui', ['sidebarAlwaysOpened']),

    alwaysOpened: {
      get() {
        return this.sidebarAlwaysOpened;
      },
      set(value) {
        this.setSidebarAlwaysOpened(value);
      },
    },
  },

  watch: {
    value(value) {
      if (value !== this.opened) {
        this.opened = value;
      }
    },

    opened(opened) {
      this.$emit('input', opened);
    },

    $route() {
      this.opened = false;
    },
  },

  mounted() {
    const clickOutHandler = (event) => {
      if (!this.$el.contains(event.target)) {
        this.opened = false;
      }
    };

    document.addEventListener('click', clickOutHandler);

    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('click', clickOutHandler);
    });
  },

  methods: {
    ...mapMutations('ui', ['setSidebarAlwaysOpened']),
  },
};
</script>
