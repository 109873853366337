
import Vue, { PropType } from 'vue';
import { BPagination } from 'bootstrap-vue';

export default Vue.extend({
  name: 'VPagePagination',

  components: {
    BPagination,
  },

  model: {
    prop: 'page',
  },

  props: {
    itemCount: {
      type: Number,
      required: true,
    },

    page: {
      type: Number,
      default: 1,
    },

    perPageOptions: {
      type: Array as PropType<number[]>,
      default: () => [10, 20, 50, 100],
    },

    perPage: {
      type: Number,
      default(): number {
        return this.perPageOptions[0];
      },
    },
  },

  computed: {
    lastPage(): number {
      return Math.ceil(this.itemCount / this.perPage) || 0;
    },

    firstIndex(): number {
      if (this.itemCount <= 0) {
        return 0;
      }

      return (this.page - 1) * this.perPage + 1;
    },

    lastIndex(): number {
      if (this.itemCount <= 0) {
        return 0;
      }

      return this.page === this.lastPage ? this.itemCount : this.page * this.perPage;
    },
  },

  methods: {
    selectPerPage(perPage: number) {
      this.$emit('select-per-page', perPage);
    },

    selectPage(page: number) {
      this.$emit('input', page);
    },
  },
});
