import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  Gtin: string;
  MarktId: UUID;
}

export interface Response {
  IstGtinVergeben: boolean;
  ArtikelData: {
    Id: UUID;
    Setnummer: number;
    Artikelbezeichnung: string;
    Inhalt: string;
    ArtikelBereitsGelistet: boolean;
  };
}

export async function gtinValidieren(request: Request): Promise<Response> {
  return client.request('art', 'GtinValidieren', request)
    .then(response => response.data);
}
