import client from '@/api/client';
import { UUID } from '@/api/bitwws/types';

export interface Request {
  UnternehmenId: UUID;
  KundeId: UUID;
}

export interface Response {
  ExistiertBereitsEinLieferant: boolean;
  ExistiertBereitsEinAbkommenDesKundens: boolean;
}

export async function lieferantenAnlegenValidieren(request: Request): Promise<Response> {
  return client.request('lua', 'LieferantenAnlegenValidieren', request)
    .then(response => response.data);
}
