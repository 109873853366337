<template>
  <b-list-group-item
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </b-list-group-item>
</template>

<script>
import { BListGroupItem } from 'bootstrap-vue';

export default {
  name: 'VListGroupItem',

  components: {
    BListGroupItem,
  },

  inheritAttrs: false,
};
</script>
