<template>
  <div :class="{ 'col': !auto, 'col-auto': auto }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VColumn',

  props: {
    auto: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
