class LocalStorage {
  public static get(key: string, defaultValue: string|null = null): string|null {
    const value = localStorage.getItem(key);

    if (value === null) {
      return defaultValue;
    }

    return value;
  }

  public static getJson<T>(key: string): T|null {
    const value = LocalStorage.get(key);

    if (value === null) {
      return null;
    }

    return JSON.parse(value);
  }

  public static getJsonOrDefault<T>(key: string, defaultValue: T): T {
    const value = LocalStorage.get(key);

    if (value === null) {
      return defaultValue;
    }

    return JSON.parse(value);
  }

  public static set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public static setJson(key: string, value: unknown): void {
    LocalStorage.set(key, JSON.stringify(value));
  }
}

export default LocalStorage;
