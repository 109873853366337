<template>
  <v-dropdown
    v-if="computedActions.length > 0"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #button-content>
      <v-icon
        icon="ellipsis-v"
        fixed-width
      />
      <span class="sr-only">
        {{ $t('components.list_group_action.actions') }}
      </span>
    </template>

    <template v-for="(action) in computedActions">
      <v-dropdown-item
        v-if="action.to"
        :key="action.id"
        :danger="action.danger"
        :to="getTo(action.to)"
      >
        {{ action.label }}
      </v-dropdown-item>

      <v-dropdown-item-button
        v-else
        :key="action.id"
        :danger="action.danger"
        @click="action.handler()"
      >
        {{ action.label }}
      </v-dropdown-item-button>
    </template>
  </v-dropdown>
</template>

<script>
import { uniqueId } from 'lodash';

export default {
  name: 'VListGroupAction',

  props: {
    actions: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    computedActions() {
      return this.actions
        .filter((action) => typeof action.when === 'undefined' || action.when())
        .map((action) => ({ id: uniqueId(), ...action }));
    },
  },

  methods: {
    getUniqueId() {
      return uniqueId();
    },
  },
};
</script>
