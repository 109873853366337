
import { BDropdownItemButton } from 'bootstrap-vue';

export default {
  name: 'VDropdownItemButton',

  components: {
    BDropdownItemButton,
  },

  inheritAttrs: false,

  props: {
    danger: {
      type: Boolean,
      default: false,
    },
  },
};
