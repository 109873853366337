<template>
  <header
    class="page-header"
    :style="styles"
  >
    <div class="page-header-content">
      <div class="page-header-title">
        <v-skeleton v-if="loading" />

        <h2 v-else>
          <slot />
        </h2>
      </div>

      <div
        v-if="hasActionSlot"
        class="page-header-actions"
      >
        <slot name="actions" />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'VPageHeader',

  inject: {
    page: {
      default: null,
    },
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasActionSlot() {
      return this.$slots['actions'] !== undefined;
    },

    styles() {
      if (!this.page) {
        return {};
      }

      return {
        'padding-right': `${this.page.scrollbarOffset}px`,
      };
    },
  },
};
</script>
