import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  VerpackungId: UUID;
  VerpackungRowVersion: number;
  GueltigVon: string;
  GueltigBis: string;
  GtinId: UUID;
  BisherigeHauptGtinLoeschen: boolean;
  LoeschdatumHauptGtin: string;
  KundeId: UUID;
}

export async function hauptgtinFestlegen(request: Request): Promise<null> {
  return client.request('art', 'HauptgtinFestlegen', request)
    .then(response => response.data);
}
