import Axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';

interface Config {
  baseUrl: string;
}

interface RegisterRequest {
  username: string;
  password: string;
  invitationCode: string;
}


interface RegisterResponse {
  username?: string;
}

interface LoginResponse {
  username: string;
  name: string;
}

interface ErrorHandler {
  (response: AxiosResponse): void;
}

export class Server {
  private httpClient: AxiosInstance;

  constructor(config: Config) {
    this.httpClient = Axios.create({
      baseURL: config.baseUrl,
      withCredentials: true,
    });
  }

  public async login(
    username: string,
    password: string,
    rememberMe: boolean,
  ): Promise<LoginResponse> {
    return this.httpClient.post('/login', { username, password, rememberMe })
      .then(response => response.data);
  }

  public async logout(): Promise<void> {
    return this.httpClient.get('/logout');
  }

  public async retrieveToken(): Promise<string> {
    return this.httpClient.get('/token')
      .then(response => response.data.token);
  }

  public async register(data: RegisterRequest): Promise<RegisterResponse> {
    return this.httpClient.post('/register', data);
  }

  public async requestPasswordReset(username: string) {
    return this.httpClient.post('/password-reset/request', { username });
  }

  public async resetPassword(token: string, password: string) {
    return this.httpClient.post(`/password-reset/${token}`, { password });
  }

  public async getConfig() {
    return this.httpClient.get('/config');
  }

  public async adminPasswordResetRequest(userId: string) {
    return this.httpClient.post('/admin/password-reset/request', { bitwwsUserId: userId });
  }

  public async adminReinvite(userId: string) {
    return this.httpClient.post('/admin/invitations', { bitwwsUserId: userId });
  }

  public async adminLock(userId: string) {
    return this.httpClient.put(`/admin/user/${userId}/lock`);
  }

  public async adminUnlock(userId: string) {
    return this.httpClient.delete(`/admin/user/${userId}/lock`);
  }

  public registerErrorHandler(statusCodes: number[], errorHandler: ErrorHandler) {
    this.httpClient.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        const { response } = error;

        if (response && statusCodes.includes(response.status)) {
          return errorHandler(response);
        }

        return Promise.reject(error);
      },
    );
  }
}

export default new Server({
  baseUrl: process.env.VUE_APP_SERVER_URL,
});
