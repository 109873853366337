import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  UnternehmenId: UUID;
}

export interface Response {
    KeinEtikett: boolean;
}

export async function lieferantFuerAnlageLaden(request: Request): Promise<Response> {
  return client.request('lua', 'LieferantFuerAnlageLaden', request)
    .then(response => response.data);
}
