
import { BCollapse } from 'bootstrap-vue';
import uniqueId from 'lodash/uniqueId';

export default {
  name: 'VCollapse',

  components: {
    BCollapse,
  },

  inheritAttrs: false,

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    id: {
      type: String,
      default: () => uniqueId('v-collapse-'),
    },
  },
};
