<template>
  <div id="app">
    <router-view v-if="initialized" />
    <app-loading v-else />

    <v-modal
      v-model="networkErrorModal"
      hide-footer
    >
      <template #modal-title>
        <v-icon
          icon="exclamation-triangle"
          class="modal-title-icon text-danger"
        />

        {{ $t('components.app.network_error') }}
      </template>

      <dl>
        <dt>{{ $t('components.app.error_message') }}</dt>
        <dd>{{ networkError.message }}</dd>

        <dt>{{ $t('components.app.response') }}</dt>
        <dd>{{ networkError.response || $t('common.not_available') }}</dd>

        <dt>
          <abbr :title="$t('components.app.uniform_resource_locator')">
            {{ $t('components.app.url') }}
          </abbr>
        </dt>
        <dd>{{ networkError.url }}</dd>
      </dl>
    </v-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppLoading from '@/components/app/AppLoading.vue';

import client from '@/api/client';

export default {
  name: 'App',

  components: {
    AppLoading,
  },

  data() {
    return {
      networkErrorModal: false,
      networkError: {
        message: '',
        response: null,
        url: '',
      },
    };
  },

  computed: {
    ...mapState(['initialized']),

    ...mapState('systemConfig', ['config']),
  },

  watch: {
    'config.maintenance.active': {
      handler(active) {
        if (active) {
          this.$router.replace('/maintenance');
        }
      },
      immediate: true,
    },
  },

  mounted() {
    client.errorHandler((error) => {
      this.networkError.message = error.message;

      if (error.response) {
        this.networkError.response = error.response.data;
      } else {
        this.networkError.response = null;
      }

      this.networkError.url = error.config.url;
      this.networkErrorModal = true;
    });
  },
};
</script>

<style lang="scss">
@import "@/styles/main.scss";
</style>
