import client from '@/api/client';
import { UUID } from './types';

export enum ArtikelArt {
  DEZENTRAL = 'Dezentral',
  ZENTRAL = 'Zentral',
}

export interface ArtikelAnlegenData {
  SortimentId: UUID;
  WarengruppeId: UUID;
  HauptplatzierungId: UUID;
  Bezeichnung: string;
  VerpackungsartId: UUID;
  Inhaltanzahl: number;
  Inhaltmenge: number;
  InhalteinheitId: UUID;
  IstPseudoArtikel: boolean;
  Umsatzsteuerkennzeichen: string;
  UmsatzsteuerId: UUID;
  GueltigAb: string;
  WarennummerAussenhandelSchluessel: null;
  ArtDerVerkaufseinheit: number;
  Marke: null;
  PreiseingabeAnKasse: boolean;
  IstRabattfaehig: boolean | null;
}

export interface VerpackungAnlegenData {
  Hoehe: number | null;
  Tiefe: number | null;
  Breite: number | null;
  Gewicht: number | null;
  MengeArtikelInVerpackung: number;
  Bezeichnung: string;
  IstEgalisiert: boolean | null;
  VerkaufsverpackungData: {
    SollGrundpreisAngegebenWerden: boolean;
    GrundpreisinhaltAutomatischBerechnen: boolean;
    RelevanteFuellMenge: string;
    RelevanteFuellMengeEinheitId: UUID;
    BasisMenge: number;
    BasisMengeEinheitId: UUID;
    Regaltext: string;
    Bontext: string;
    PlakatText: string;
    FreiePluVergeben: boolean;
    PluKreis: number | null;
    PluNummer: string | null;
  };
  BezugsgebindeData: {
    IstDisplay: boolean;
    IstStapelbar: boolean;
    IstPalette: boolean;
    GebindeartId: UUID | null;
    AnzahlVerpackungenProUmkarton: null;
    AnzahlVerpackungProLage: null;
    AnzahlVerpackungProPalette: null;
    AnliefereinheitId: null;
    Palettenfaktor: null;
    BezugsgebindeInhaltFaktor: null;
    BezugsgebindeInhaltMenge: null;
    BezugsgebindeInhaltMengeEinheitId: null;
    BezugspaletteGtin: null;
    BezugspaletteVerpackungsbasisGtin: null;
    BezugspaletteHoehe: null;
    BezugspaletteBruttogewicht: null;
    LiefereinheitAnlegenData: {
      LieferantId: UUID;
      Lieferantenartikelnummer: null;
      Leitnummer: null;
      ZeiteinheitId: null;
      Restlaufzeit: null;
      AbweichendeMengeInVerpackung: null;
      AbweichendeVerpackungenProLage: null;
      AbweichendeVerpackungenProPalette: null;
    };
  };
  ArtikelleergutZuordnenData: null;
}

export interface Request {
  ArtikelId: UUID;
}

export interface Response {
  ArtikelAnlegenData: ArtikelAnlegenData;
  VerpackungAnlegenDataList: VerpackungAnlegenData[];
  SeenWarnings: null;
}

export async function artikelKopierenVorbelegen(request: Request): Promise<Response> {
  return client.request('art', 'ArtikelKopierenVorbelegen', request)
    .then(response => response.data);
}
