
import Vue, { PropType, VueConstructor } from 'vue';
import { BTable } from 'bootstrap-vue';
import { RawLocation } from 'vue-router';
import { uniqueId } from 'lodash';

interface Component {
  $refs: {
    table: BTable;
  };
}

interface Action {
  label: string;
  handler?: (data: unknown) => void;
  to?: RawLocation | ((data: unknown) => RawLocation);
  when?: (data: unknown) => boolean;
  danger: boolean;
}

export default (Vue as VueConstructor<Component & Vue>).extend({
  name: 'VTable',

  components: {
    BTable,
  },

  inheritAttrs: false,

  props: {
    fields: {
      type: Array,
      default: () => [],
    },

    actions: {
      type: Array as PropType<Action[]>,
      default: () => [],
    },
  },

  computed: {
    computedFields() {
      if (this.actions.length > 0) {
        return [
          ...this.fields,
          {
            key: '_actions',
            label: '',
          },
        ];
      }

      return this.fields;
    },
  },

  methods: {
    clearSelected() {
      this.$refs.table.clearSelected();
    },

    selectRow(index: number) {
      this.$refs.table.selectRow(index);
    },

    unselectRow(index: number) {
      this.$refs.table.unselectRow(index);
    },

    isRowSelected(index: number) {
      this.$refs.table.isRowSelected(index);
    },

    selectAllRows() {
      this.$refs.table.selectAllRows();
    },

    getActions(data: unknown) {
      return this.actions
        .filter((action) => typeof action.when === 'undefined' || action.when(data))
        .map((action) => ({ id: uniqueId(), ...action }));
    },

    getUniqueId() {
      return uniqueId();
    },

    getRoute(to: RawLocation | ((data: unknown) => RawLocation), data: unknown) {
      if (typeof to === 'function') {
        return to(data);
      }

      return to;
    },
  },
});
