import client from '@/api/client';
import { UUID } from './types';
import { Hauptkommunikationsart } from './enums/Hauptkommunikationsart';

export interface Request {
  Gln: string;
}

export interface Response {
  UnternehmenId: UUID;
  Firma: string;
  Kurzname: string;
  Geschaeftsbereich: string;
  Gln: string;
  UmsatzsteuerLand: null;
  UmsatzsteuerIdentnummer: null;
  Steuernummer: null;
  Verpackungsregistrierungsnummer: null;
  Hauptkommunikationsart: Hauptkommunikationsart;
  Zustellangabe: {
    Zustelltext: null;
    Strasse: string;
    Hausnummer: string;
    Plz: string;
    Ort: string;
    Nation: {
      Id: UUID;
      Name: string;
    };
  } | null;
  Abholangabe: {
    Postfach: string;
    Plz: string;
    Ort: string;
    Nation: {
      Id: UUID;
      Name: string;
    };
  } | null;
  Kommunikationsdaten: {
    Art: string;
    Bezeichnung: string;
    NummerAdresse: string;
  }[];
}

export interface Umsatzsteuerart {
  Id: string;
  BezeichnungUndProzentWert: string;
}

export async function unternehmenMitGlnLaden(request: Request): Promise<Response> {
  return client.request('org', 'UnternehmenMitGlnLaden', request)
    .then(response => response.data);
}
