import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  ArtikelId: string;
  KundeId: UUID;
  Stichtag: string;
  ArtikelRowVersion: number;
  SpezialattributAendernDataList: SpezialattributAendernData[];
}

export interface SpezialattributAendernData {
  SpezialattributTyp: string;
  SpezialattributKuerzel: string;
  Wert: unknown;
  EinheitId: string;
  DomaenenKuerzel: string[];
  NaehrwertDataList: NaehrwertData[];
}

export interface NaehrwertData {
  Kennzeichen: string;
  Wert: number;
}

export async function spezialattributeEinesArtikelsAendern(request: Request): Promise<void> {
  return client.request('art', 'SpezialattributeEinesArtikelsAendern', request)
    .then(response => response.data);
}
