import client from '@/api/client';
import { UUID } from '@/api/bitwws/types';

export interface Request {
  Feldname: 'Name' | 'Kurzname' | 'Verpackungsregistrierungsnummer';
  Wert: string;
  UnternehmenId: UUID;
}

export async function unternehmenFeldnamenValidieren(request: Request): Promise<null> {
  return client.request('org', 'UnternehmenFeldnamenValidieren', request)
    .then(response => response.data);
}
