import client from '@/api/client';
import { UUID } from '@/api/bitwws/types';

export interface Request {
  KundeId: UUID;
  Stichtag: string;
  Suchtext: string;
}

export interface Response {
  Lieferanten: Lieferant[];
}

export interface Lieferant {
  LieferantId: UUID;
  UnternehmenId: UUID;
  DezentraleLieferantenNr: string;
  ZentraleLieferantenNr: null;
  Bezeichnung: string;
  Kurzname: string;
  Gln: string;
  Plz: string;
  Ort: string;
  Nation: string;
  DarfUnternehmenGeaendertWerden: boolean;
}

export interface Kontaktdaten {
  Id: UUID;
  ZustellPlz: string;
  Ort: string;
  Nation: string;
}

export async function lieferantSuchen(request: Request): Promise<Response> {
  return client.request('lua', 'LieferantSuchen', request)
    .then(response => response.data);
}
