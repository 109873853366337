
import Vue from 'vue';

export default Vue.extend({
  name: 'VSection',

  props: {
    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    hasActionsSlot(): boolean {
      return this.$slots['actions'] !== undefined;
    },
  },
});
