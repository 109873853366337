<template>
  <b-form-textarea
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue';

export default {
  name: 'VTextarea',

  components: {
    BFormTextarea,
  },

  inheritAttrs: false,
};
</script>
