
import { BForm } from 'bootstrap-vue';

export default {
  name: 'VForm',

  components: {
    BForm,
  },

  inheritAttrs: false,
};
