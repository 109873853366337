
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'VIcon',

  components: {
    FontAwesomeIcon,
  },

  inheritAttrs: false,
};
