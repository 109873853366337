import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  ArtikelId: string;
  FilialId: string;
  Stichtag: string;
}

export interface Response {
  BezugsInformationen: BezugsInformation[];
}

export interface BezugsInformation {
  VerpackungId: UUID;
  VerpackungRowVersion: number;
  MengeArtikelInVerpackung: number;
  Gtin: string;
  Bezeichnung: string | null;
  Hoehe: number;
  Breite: number;
  Tiefe: number;
  Gewicht: number;
  Bezugseinheiten: Bezugseinheit[];
  KundeDarfVerpackungAendern: boolean;
}

export interface Bezugseinheit {
  Id: UUID;
  BezugseinheitRowVersion: number;
  Lieferant: string;
  Lieferantenartikelnummer: string | null;
  Zan: string;
  KundeDarfBezugAendern: boolean;
}

export async function bezuegeEinesArtikelsLaden(request: Request): Promise<Response> {
  return client.request('art', 'BezuegeEinesArtikelsLaden', request)
    .then(response => response.data);
}
