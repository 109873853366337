import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  ArtikelId: UUID;
  Stichtag: string;
  FilialId: UUID;
}

export interface Response {
  ArtikelAendernLadenData: ArtikelAendernLadenData;
}

export interface ArtikelAendernLadenData {
  ArtikelId: UUID;
  VerpackungId: UUID;
  VerkaufsverpackungId: UUID;
  ArtikelRowVersion: number;
  VerpackungRowVersion: number;
  VerkaufsverpackungRowVersion: number;
  Artikelbezeichnung: string;
  VerpackungsartId: UUID;
  InhaltFaktor: number;
  InhaltMenge: number;
  InhaltEinheitId: UUID;
  ArtDerVerkaufseinheit: number;
  UmsatzsteuerartId: UUID;
  WarennummerAussenhandelSchluessel: null;
  HauptplatzierungId: UUID;
  SortimentId: UUID;
  LieferantId: UUID;
  Gtin: string;
  Regaltext: string;
  Bontext: string;
  PluKreis: number | null;
  Plu: number | null;
  WarengruppeId: UUID;
  LeergutId: UUID | null;
  PreiseingabeAnKasse: boolean;
  IstRabattfaehig: boolean;
  HatVerkaufsverpackung: boolean;
  HatBezugseinheit: boolean;
}

export async function artikelAendernLaden(request: Request): Promise<Response> {
  return client.request('art', 'HofArtikelAendernLaden', request)
    .then(response => response.data);
}
