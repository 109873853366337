import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  FilialeId: UUID;
  VerpackungId: UUID;
  RowVersion: number;
  Stichtag: string;
  GewichtGeandert: boolean;
  Gewicht: number;
  AbmessungGeandert: boolean;
  Hoehe: number;
  Breite: number;
  Tiefe: number;
  MengeGeandert: boolean;
  MengeArtikelInVerpackung: number;
  GtinGeandert: boolean;
  HausinterneGtinVergeben: boolean;
  Gtin: number;
}

export async function verpackungAendern(request: Request): Promise<null> {
  return client.request('art', 'HofVerpackungAendern', request)
    .then(response => response.data);
}
