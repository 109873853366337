import server from './server';

class LoginService {
  private token: string|null = null;

  public async login(username: string, password: string, rememberMe: boolean) {
    return server.login(username, password, rememberMe).then((response) => {
      return server.retrieveToken().then((token) => {
        this.token = token;

        return response;
      });
    });
  }

  public async refreshToken() {
    return server.retrieveToken().then((newToken) => {
      this.token = newToken;

      return newToken;
    });
  }

  public logout() {
    this.token = null;

    server.logout();
  }

  public getToken() {
    return this.token;
  }
}

export default new LoginService();
