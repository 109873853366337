<template>
  <b-button
    :disabled="finalDisabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import disabledChild from '@/mixins/disabled-child';

export default {
  name: 'VButton',

  components: {
    BButton,
  },

  mixins: [disabledChild],

  inheritAttrs: false,
};
</script>
