<template>
  <div class="wizard">
    <div
      v-if="activeStep && !activeStep.isFinishedStep"
      class="wizard-nav"
      :style="styles"
    >
      <ul>
        <template
          v-for="(step, index) in shownSteps"
        >
          <li
            v-if="!step.isFinishedStep"
            :key="step.id"
            class="wizard-step"
            :class="{ disabled: step.disabled, active: step.active }"
          >
            <a
              :href="!step.disabled ? `#${step.id}` : null"
              @click.prevent="() => activateStep(index)"
            >
              <div class="wizard-step-number">{{ index + 1 }}</div>
              <div class="wizard-step-name">{{ step.name }}</div>
            </a>
          </li>
        </template>
      </ul>
    </div>

    <div class="wizard-content">
      <slot
        :next="next"
        :previous="previous"
        :has-reached="hasReached"
      />
    </div>

    <div
      v-if="activeStep && !activeStep.isFinishedStep"
      class="wizard-footer"
      :style="styles"
    >
      <div class="wizard-footer-content">
        <v-button
          variant="link"
          @click="cancel"
        >
          {{ $t('components.wizard.cancel') }}
        </v-button>

        <v-button
          :key="`previous-button-${activeStep.id}`"
          variant="secondary"
          :disabled="previousDisabled"
          @click="previous"
        >
          {{ $t('components.wizard.back') }}
        </v-button>

        <v-loading-button
          :key="`next-button-${activeStep.id}`"
          :type="activeStep.formId ? 'submit' : 'button'"
          variant="primary"
          :disabled="nextDisabled"
          :loading="activeStep.loading"
          :form="activeStep.formId"
          @click="onNext"
        >
          {{ activeStep.nextButtonText || $t('components.wizard.next') }}
        </v-loading-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VWizard',

  provide() {
    return {
      wizard: this,
    };
  },

  inject: {
    page: {
      default: null,
    },
  },

  model: {
    prop: 'activeStepId',
  },

  props: {
    activeStepId: {
      type: [String, Number],
      default: null,
    },

    reachedStepId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      steps: [],
      activeStepIndex: 0,
      reachedStepIndex: 0,
    };
  },

  computed: {
    shownSteps() {
      return this.steps.filter(step => step.show);
    },

    activeStep() {
      return this.steps[this.activeStepIndex] ?? null;
    },

    previousStep() {
      if (this.activeStepIndex > 0 && this.activeStepIndex < this.steps.length) {
        return this.steps[this.activeStepIndex - 1] ?? null;
      }

      return null;
    },

    previousDisabled() {
      if (this.activeStepIndex === 0) {
        return true;
      }

      if (this.previousStep !== null && this.previousStep.disabled) {
        return true;
      }

      if (this.activeStep !== null && this.activeStep.loading) {
        return true;
      }

      return false;
    },

    nextDisabled() {
      return this.activeStep !== null && !this.activeStep.enableNext;
    },

    styles() {
      if (!this.page) {
        return {};
      }

      return {
        'padding-right': `${this.page.scrollbarOffset}px`,
      };
    },
  },

  watch: {
    activeStepId(newStepId) {
      this.activateStepById(newStepId);
    },

    activeStepIndex(newIndex, oldIndex) {
      this.$emit('input', this.steps[newIndex].id);

      if (newIndex > oldIndex) {
        this.reachedStepIndex = newIndex;
      }
    },

    reachedStepIndex(newIndex) {
      this.$emit('reached', this.steps[newIndex].id);
    },

    reachedStepId(newStepId) {
      if (newStepId !== null) {
        this.reachedStepIndex = this.getStepIndex(newStepId);
      }
    },
  },

  methods: {
    getStepIndex(stepId) {
      return this.steps.findIndex(step => step.id === stepId);
    },

    addStep(step) {
      this.steps.push(step);

      this.update();
    },

    removeStep(step) {
      this.steps.splice(this.getStepIndex(step.id), 1);

      this.update();
    },

    update() {
      const newActiveStepIndex = this.getStepIndex(this.activeStepId);

      if (newActiveStepIndex > 0) {
        this.activeStepIndex = newActiveStepIndex;
      }
    },

    activateStep(index) {
      if (index >= 0 && index < this.steps.length) {
        this.activeStepIndex = index;
      }
    },

    activateStepById(id) {
      this.activateStep(this.getStepIndex(id));
    },

    next() {
      const nextStep = this.getNextAvailableStep();

      if (nextStep) {
        this.activateStep(this.steps.indexOf(nextStep));
      }
    },

    previous() {
      const nextStep = this.getPreviousAvailableStep();

      if (nextStep) {
        this.activateStep(this.steps.indexOf(nextStep));
      }
    },

    cancel() {
      this.$emit('cancel');
    },

    onNext() {
      if (!this.activeStep || this.activeStep.formId === null) {
        this.next();
      }
    },

    hasReached(stepId) {
      return this.reachedStepIndex >= this.getStepIndex(stepId);
    },

    getNextAvailableStep() {
      const activeStepIndex = this.shownSteps.indexOf(this.activeStep);
      const stepIndex = activeStepIndex + 1;

      if (stepIndex < this.shownSteps.length) {
        return this.shownSteps[stepIndex];
      }

      return null;
    },

    getPreviousAvailableStep() {
      const activeStepIndex = this.shownSteps.indexOf(this.activeStep);
      const stepIndex = activeStepIndex - 1;

      if (stepIndex >= 0) {
        return this.shownSteps[stepIndex];
      }

      return null;
    },
  },
};
</script>
