import client from '@/api/client';
import { UUID } from '@/api/bitwws/types';

export interface Request {
  VerpackungId: string;
  Stichtag: string;
}

export interface Response {
  VerpackungRowVersion: number;
  GtinDatas: GtinData[];
}

export interface GtinData {
  Id: UUID;
  Gtin: string;
  IstHauptGtin: boolean;
  IstHausintern: boolean;
  GueltigkeitHinweis: string | null;
}

export async function gtinsEinerVerpackungLaden(request: Request): Promise<Response> {
  return client.request('art', 'GtinsEinerVerpackungLaden', request)
    .then(response => response.data);
}
