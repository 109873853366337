import { Module } from 'vuex';
import { State as RootState } from '.';

import { artikelSuchen, artikelFuerSucheLaden } from '@/api/bitwws';

import { formatIso } from '@/utils/date';
import { Artikel, Bezug } from '@/api/bitwws/artikelFuerSucheLaden';
import { fetchPurchaseItemBySupplierItemNumbers } from '@/utils/fetchPurchaseItem';

export enum ArticleStatus {
  STATUS_ALL,
  STATUS_ACTIVE,
  STATUS_BLOCKED,
}

interface SearchData {
  query: string;
  productGroupId: string | null;
  supplierId: string | null;
  manufacturerId: string | null;
  onlyOwnArticles: boolean;
  onlyRetailArticles: boolean;
  onlyListed: boolean;
  status: ArticleStatus;
  supplierItemNumbers: string | null;
}

interface Article extends Artikel {
  selectedSource: Bezug;
}

interface State {
  articles: Article[];
  searchData: SearchData;
  count: number;
  initialized: boolean;
  storeId: string | null;
  sort: unknown[];
}

const storeModule: Module<State, RootState> = {
  namespaced: true,

  state: {
    articles: [],
    searchData: {
      query: '',
      productGroupId: null,
      supplierId: null,
      manufacturerId: null,
      onlyOwnArticles: false,
      onlyRetailArticles: true,
      onlyListed: true,
      status: ArticleStatus.STATUS_ALL,
      supplierItemNumbers: null,
    },
    sort: [],
    count: 100,
    initialized: false,
    storeId: null,
  },

  mutations: {
    setArticles(state, articles) {
      state.articles = articles;
    },

    updateSelectedSource(state, { article, selectedSource }) {
      article.selectedSource = selectedSource;
    },

    setInitialized(state, initialized) {
      state.initialized = initialized;
    },

    setStoreId(state, storeId) {
      state.storeId = storeId;
    },

    setCount(state, count) {
      state.count = count;
    },

    updateSearchData(state, {
      searchData,
      query = searchData.query,
      productGroupId = searchData.productGroupId,
      supplierId = searchData.supplierId,
      manufacturerId = searchData.manufacturerId,
      onlyOwnArticles = searchData.onlyOwnArticles,
      onlyRetailArticles = searchData.onlyRetailArticles,
      onlyListed = searchData.onlyListed,
      status = searchData.status,
      supplierItemNumbers = searchData.supplierItemNumbers,
    }) {
      state.searchData.query = query;
      state.searchData.productGroupId = productGroupId;
      state.searchData.supplierId = supplierId;
      state.searchData.manufacturerId = manufacturerId;
      state.searchData.onlyOwnArticles = onlyOwnArticles;
      state.searchData.onlyRetailArticles = onlyRetailArticles;
      state.searchData.onlyListed = onlyListed;
      state.searchData.status = status;
      state.searchData.supplierItemNumbers = supplierItemNumbers;
    },

    resetSearchData(state) {
      state.searchData.query = '';
      state.searchData.productGroupId = null;
      state.searchData.supplierId = null;
      state.searchData.manufacturerId = null;
      state.searchData.onlyOwnArticles = false;
      state.searchData.onlyRetailArticles = true;
      state.searchData.onlyListed = true;
      state.searchData.supplierItemNumbers = null;
      state.searchData.status = ArticleStatus.STATUS_ALL;
    },

    updateSort(state, sort) {
      state.sort = sort;
    },
  },

  actions: {
    fetchArticles({ commit, state, rootState, rootGetters }) {
      return artikelSuchen({
        Suchtext: state.searchData.query,
        HauptGtin: '',
        SortimentId: null,
        WarengruppeId: state.searchData.productGroupId,
        LieferantId: state.searchData.supplierId,
        HauptplazierungId: null,
        FilialeId: rootGetters.currentStoreId,
        HerstellerId: state.searchData.manufacturerId,
        SucheNurEigeneArtikel: state.searchData.onlyOwnArticles,
        SucheNurVerkaufsartikel: state.searchData.onlyRetailArticles,
        Stichtag: formatIso(rootState.currentDate),
        MaximaleAnzahlTreffer: state.count,
        NurAktiveArtikel: state.searchData.status === ArticleStatus.STATUS_ACTIVE,
        NurGesperrteArtikel: state.searchData.status === ArticleStatus.STATUS_BLOCKED,
        NurGelisteteArtikel: state.searchData.onlyListed,
        Lieferantenartikelnummern: state.searchData.supplierItemNumbers,
      })
        .then((response) => {
          return artikelFuerSucheLaden({
            FilialeId: rootGetters.currentStoreId,
            Stichtag: formatIso(rootState.currentDate),
            ArtikelIds: response.ArtikelIds,
          });
        })
        .then((response) => {
          commit('setArticles', response.ArtikelDatas.map((article) => ({
            ...article,
            selectedSource: fetchPurchaseItemBySupplierItemNumbers(article.Bezuege, state.searchData.supplierItemNumbers),
          })));
        });
    },
  },
};

export default storeModule;
