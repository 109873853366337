
import { AttributeService } from './service';
import { AttibuteTypeConfig, AttributeConfig } from './config';
import { getAttributeValue, whenAttribute } from './attribute';

export const typeConfig: Array<AttibuteTypeConfig> = [
  {
    type: 'domain',
    apiType: 'Domaene',
    defaultValue: () => [],
    optionBuilder(apiAttribute) {
      return {
        choices: (apiAttribute.Werte ?? []).map(wert => ({
          value: wert.Kennzeichen,
          label: wert.Bezeichnung,
        })),
        multiple: apiAttribute.ErlaubtMehrfachauswahl,
      };
    },
    sendTransformer(attribute) {
      return {
        DomaenenKuerzel: attribute.value as string[],
      };
    },
    readTransformer(apiAttribute) {
      if (Array.isArray(apiAttribute.Wert)) {
        return apiAttribute.Wert;
      }

      if (apiAttribute.Wert === null) {
        return [];
      }

      return [apiAttribute.Wert];
    },
    isEmpty(attribute) {
      return Array.isArray(attribute.value) && attribute.value.length === 0;
    },
  },

  {
    type: 'enum',
    apiType: 'Enum',
    defaultValue: () => [],
    optionBuilder(apiAttribute) {
      return {
        choices: (apiAttribute.Werte ?? []).map(wert => ({
          value: wert.Kennzeichen,
          label: wert.Bezeichnung,
        })),
        multiple: apiAttribute.ErlaubtMehrfachauswahl,
      };
    },
    sendTransformer(attribute) {
      return {
        Wert: attribute.value,
      };
    },
    readTransformer(apiAttribute) {
      return apiAttribute.Wert;
    },
    isEmpty(attribute) {
      return attribute.value === [];
    },
  },

  {
    type: 'flag',
    apiType: 'Flag',
    defaultValue: () => null,
    sendTransformer(attribute) {
      return {
        Wert: attribute.value,
      };
    },
    readTransformer(apiAttribute) {
      return apiAttribute.Wert;
    },
    isEmpty(attribute) {
      return attribute.value === null;
    },
  },

  {
    type: 'text',
    apiType: 'Text',
    defaultValue: () => null,
    sendTransformer(attribute) {
      return {
        Wert: attribute.value,
      };
    },
    readTransformer(apiAttribute) {
      return apiAttribute.Wert;
    },
    isEmpty(attribute) {
      return attribute.value === null;
    },
  },

  {
    type: 'html',
    apiType: 'Html',
    defaultValue: () => null,
    sendTransformer(attribute) {
      return {
        Wert: attribute.value,
      };
    },
    readTransformer(apiAttribute) {
      return apiAttribute.Wert;
    },
    isEmpty(attribute) {
      return attribute.value === null;
    },
  },

  {
    type: 'number',
    apiType: 'Zahl',
    defaultValue: () => null,
    optionBuilder(apiAttribute) {
      return {
        fractionDigits: apiAttribute.Dezimalstellen ?? null,
        min: apiAttribute.MinimalWert ?? null,
        max: apiAttribute.MaximalWert ?? null,
      };
    },
    sendTransformer(attribute) {
      return {
        Wert: attribute.value,
      };
    },
    readTransformer(apiAttribute) {
      return apiAttribute.Wert;
    },
    isEmpty(attribute) {
      return attribute.value === null;
    },
  },

  {
    type: 'amountAndUnit',
    apiType: 'MengeMitEinheit',
    defaultValue: () => ({
      amount: null,
      unit: null,
    }),
    optionBuilder(apiAttribute) {
      return {
        unitChoices: (apiAttribute.Werte ?? []).map((wert) => ({
          value: wert.Kennzeichen,
          label: wert.Bezeichnung,
        })),
        fractionDigits: apiAttribute.Dezimalstellen ?? null,
        min: apiAttribute.MinimalWert ?? null,
        max: apiAttribute.MaximalWert ?? null,
      };
    },
    sendTransformer(attribute) {
      return {
        Wert: (attribute.value as { amount: string }).amount,
        EinheitId: (attribute.value as { unit: string }).unit,
      };
    },
    readTransformer(apiAttribute) {
      return {
        amount: apiAttribute.Wert,
        unit: apiAttribute.EinheitId,
      };
    },
    isEmpty(attribute) {
      if (
        (attribute.value as { amount: unknown; unit: unknown }).amount !== undefined
          && (attribute.value as { amount: unknown; unit: unknown }).unit !== undefined
      ) {
        return (attribute.value as { amount: unknown; unit: unknown }).amount === null
          && (attribute.value as { amount: unknown; unit: unknown }).unit === null;
      }

      return false;
    },
  },

  {
    type: 'nutritionFacts',
    apiType: 'Config',
    defaultValue: (apiAttribute) => {
      const values: Record<string, null> = {};

      (apiAttribute.Werte ?? []).forEach((element) => {
        values[element.Kennzeichen] = null;
      });

      return values;
    },
    optionBuilder(apiAttribute) {
      return {
        nutritions: (apiAttribute.Werte ?? []).map((wert) => ({
          unit: wert.Einheit,
          label: wert.Bezeichnung,
          id: wert.Kennzeichen,
          required: wert.IstPflichtfeld,
        })),
      };
    },
    sendTransformer(attribute) {
      const values = attribute.value as Record<string, number | null>;

      return {
        NaehrwertDataList: Object.entries(values).map(([id, value]) => ({
          Kennzeichen: id,
          Wert: value,
        })),
      };
    },
    readTransformer() {
      return {};
    },
  },
];

export const config: Record<string, AttributeConfig> = {
  'PorGroesse': {
    when(attributes) {
      return whenAttribute(attributes, 'PorAngaben')
        && getAttributeValue(attributes, 'PorAngaben') === true;
    },
  },

  'PorBez': {
    when(attributes) {
      return whenAttribute(attributes, 'PorAngaben')
        && getAttributeValue(attributes, 'PorAngaben') === true;
    },
  },

  'MHD': {
    when(attributes) {
      const value = getAttributeValue(attributes, 'KHLFRML');

      return Array.isArray(value) && value[0] !== null;
    },
  },
};

export default new AttributeService(typeConfig, config);
export * from './attribute';
