<template>
  <div
    class="empty-state"
    :class="classes"
  >
    <slot name="icon">
      <v-icon
        :icon="icon"
        class="empty-state-icon"
      />
    </slot>

    <h1>{{ headline }}</h1>

    <slot />

    <div
      v-if="hasSlot('actions')"
      class="empty-state-actions"
    >
      <slot name="actions" />
    </div>

    <div
      v-if="hasSlot('secondary-actions')"
      class="empty-state-actions"
    >
      <slot name="secondary-actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VEmptyState',

  props: {
    icon: {
      type: String,
      required: true,
    },

    headline: {
      type: String,
      required: true,
    },

    variant: {
      type: String,
      default: null,
      validator(value) {
        return [null, 'danger', 'success'].indexOf(value) !== 1;
      },
    },
  },

  computed: {
    classes() {
      if (this.variant) {
        return [`empty-state-${this.variant}`];
      }

      return [];
    },
  },

  methods: {
    hasSlot(name) {
      return this.$slots[name] !== undefined;
    },
  },
};
</script>
