<template>
  <b-list-group
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </b-list-group>
</template>

<script>
import { BListGroup } from 'bootstrap-vue';

export default {
  name: 'VListGroup',

  components: {
    BListGroup,
  },

  inheritAttrs: false,
};
</script>
