import client from '@/api/client';
import { UUID } from './types';

export interface Response {
  Filialen: Filiale[];
}

export interface Filiale {
  Id: UUID;
  Kuerzel: string;
  Nummer: number;
  KundeId: UUID;
  Mandant: number;
}

export async function filialenLaden(): Promise<Response> {
  return client.request('hof', 'FilialenLaden', {})
    .then(response => response.data);
}
