import client from '@/api/client';
import { UUID } from './types';
import { ArtikelverbundArt } from './enums/ArtikelverbundArt';

export interface Request {
  ArtikelId: UUID;
  Stichtag: string;
}

export interface Response {
  Id: UUID;
  ArtikelId: UUID;
  AktuelleGueltigkeit: {
    GueltigVon: string;
    GueltigBis: string;
  };
  Artikelverbundtyp: ArtikelverbundArt;
  AnzahlTeile: number;
  ArtikelverbundbestandteilDatas: Artikelverbundbestandteil[];
}

export interface Artikelverbundbestandteil {
  AnzahlTeile: number;
  ArtikelBezeichnung: string;
  MengeArtikelInVerpackung: string;
  VerpackungBezeichnung: string;
  VerpackungNummer: string;
  HauptGtin: number;
  VerpackungId: UUID;
  ArtikelId: UUID;
  VerkaufsverpackungId: UUID;
}

export async function artikelverbundLaden(request: Request): Promise<Response> {
  return client.request('art', 'ArtikelverbundLaden', request)
    .then(response => response.data);
}
