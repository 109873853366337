<template>
  <b-form-select
    :value="value"
    :class="{ 'placeholder-selected': placeholderSelected }"
    :disabled="finalDisabled"
    v-bind="$attrs"
    @input="onInput"
    v-on="listeners"
  >
    <template slot="first">
      <option
        v-if="placeholder"
        :value="null"
        disabled
      >
        {{ placeholder }}
      </option>

      <slot name="start" />
    </template>

    <slot />
  </b-form-select>
</template>

<script>
import Vue from 'vue';
import { BFormSelect } from 'bootstrap-vue';
import disabledChild from '@/mixins/disabled-child';

export default Vue.extend({
  name: 'VSelect',

  components: {
    BFormSelect,
  },

  mixins: [disabledChild],

  inheritAttrs: false,

  props: {
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number, Object, Boolean, Array],
      default: null,
    },
  },

  computed: {
    placeholderSelected() {
      return this.placeholder !== null && this.value === null;
    },

    listeners() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { input, ...listeners } = this.$listeners;

      return listeners;
    },
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
  },
});
</script>
