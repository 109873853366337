import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  LieferantenId: UUID;
  KundenId: UUID;
  ZahlungszielTage: number;
  Skonto: number;
  SkontoTage: number;
  ZahlungsdatenGeaendert: boolean;
  Hauptwarengruppe: number;
  HauptwarengruppeGeaendert: boolean;
  SortimenteNeuesWws: Sortiment[];
  SortimentNeuesWwsGeaendert: boolean;
  SortimenteAs400: Sortiment[];
  SortimentAs400Geaendert: boolean;
  AbkommenId: UUID;
  AbkommenRowVersion: number;
  AbkommenListungId: UUID;
  AbkommenListungRowVersion: number;
}

export interface Sortiment {
  SortimentNummer: number;
  Status: number;
}

export async function abkommenAendern(request: Request): Promise<null> {
  return client.request('lua', 'AbkommenAendern', request)
    .then(response => response.data);
}
