import client from '@/api/client';
import { Bearbeitungsstatus } from '.';

export interface Request {
  VerkaufspreisAendernData: VerkaufspreisAendernData[];
}

export interface Response {
  PreisaenderungResponseDatas: PreisaenderungResponseData[];
}

export interface VerkaufspreisAendernData {
  Referenzkey: string;
  VerkaufspreisaenderungId: string;
  Bearbeitungsstatus: Bearbeitungsstatus;
  AngepassterVerkaufspreis?: number | string;
}

export interface PreisaenderungResponseData {
  Referenzkey: string;
  VerkaufspreisAenderungId: string;
  Bearbeitungsstatus: Bearbeitungsstatus;
  Verarbeitet: boolean;
  Fehlermeldung: string;
}

export async function verkaufspreisaenderungenBearbeiten(request: Request): Promise<Response> {
  return client.request('hof', 'VerkaufspreisaenderungenBearbeiten', request)
    .then(response => response.data);
}
