import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  ArtikelId: string;
  KundeId: UUID;
  Stichtag: string;
}

export interface Response {
  DarfKundeMarktunabhaengigeArtikeldatenAendern: boolean;
  IstArtikelAenderbar: boolean;
}

export async function artikelAendernValidieren(request: Request): Promise<Response> {
  return client.request('art', 'ArtikelAendernValidieren', request)
    .then(response => response.data);
}
