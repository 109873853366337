import client from '@/api/client';

export interface Request {
  GueltigAb?: string;
}

export interface Response {
  Umsatzsteuerartdatas: Umsatzsteuerart[];
}

export interface Umsatzsteuerart {
  Id: string;
  BezeichnungUndProzentWert: string;
  Wert: number;
  Aktiv: boolean;
}

export async function umsatzsteuerartLaden(request: Request): Promise<Response> {
  return client.request('art', 'UmsatzsteuerartLaden', request)
    .then(response => response.data);
}
