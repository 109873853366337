
import Vue from 'vue';
import { BNavItem } from 'bootstrap-vue';

export default Vue.extend({
  name: 'VNavItem',

  components: {
    BNavItem,
  },

  inheritAttrs: false,

  props: {
    to: {
      type: [String, Object],
      default: null,
    },

    exact: {
      type: Boolean,
      default: false,
    },
  },
});
