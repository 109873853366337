import client from '@/api/client';
import { UUID } from '../types';

export type Response = ServiceInformationen[];

export interface ServiceInformationen {
  ServiceInformationDatas: ServiceInformationData[];
}

export interface ServiceInformationData {
  Id: UUID;
  Titel: string;
  Nachricht: string;
  Dringlichkeit: number;
  GueltigVon: Date;
  GueltigBis: Date;
}

export async function ladeServiceinfos(): Promise<Response> {
  return client.request('wws', 'ServiceinfosLaden',{})
    .then(response => response.data);
}
