import client from '@/api/client';
import { Informationsart } from './enums/Informationsart';
import { UUID } from '@/api/bitwws/types';

export enum ArtikelArt {
  DEZENTRAL = 'Dezentral',
  DEZENTRAL_SEH = 'DezentralSeh',
  ZENTRAL = 'Zentral',
}

export interface Artikelpass {
  ArtikelId: string;
  Artikelnummer: number;
  ArtikelArt: ArtikelArt;
  Setnummer: number;
  ArtikelBezeichnung: string;
  Lieferantenbezeichnung: string | null;
  Hersteller: string;
  Marke: string|null;
  Sortiment: string;
  SortimentNummer: string;
  Hauptplatzierung: string;
  HauptplatzierungNummer: string;
  Warengruppe: string;
  WarengruppeNummer: string;
  Verpackungsart: string;
  InhaltFaktor: number;
  InhaltMenge: number;
  InhaltEinheit: string;
  IstPseudoArtikel: boolean;
  IstSonderpostenSaison: boolean;
  IstSteuerbar: boolean;
  Umsatzsteuer: string;
  UmsatzsteuerWert: number;
  Anlagedatum: string;
  WarennummerAussenhandelSchluessel: string|null;
  WarengruppeId: UUID;
  ArtDerVerkaufseinheit: string;
  IstGelistet: boolean;
  VerpackungId: string;
  Hoehe: number;
  Breite: number;
  Tiefe: number;
  Gewicht: number;
  VerpackungBezeichnung: string|null;
  IstEgalisiert: boolean;
  Gtin: string;
  Plu: number | null;
  PluKreis: number | null;
  RelevanteFuellmenge: number;
  RelevanteFuellmengeEinheit: string|null;
  Basismenge: number;
  BasismengeEinheit: string|null;
  Regaltext: string;
  Bontext: string;
  PreiseingabeAnKasse: boolean;
  IstRabattfaehig: boolean | null;
  Artikelinformationen: ArtikelinformationData[];
  Leergutzuordnung: {
    LeergutArtikelId: UUID;
    Artikelnummer: number;
    Artikelbezeichnung: string;
    LeergutKreislauf: string;
    Pfandwert: number;
  } | null;
  IstVerbundartikel: boolean;
  IstVerbundbestandteil: boolean;
  HatDezentralenBezug: boolean;
  ArtikelverbundArt: string;
}

export interface Request {
  ArtikelId: string;
  Stichtag: string;
  FilialId: UUID;
}

export interface Response {
  Artikelpass: Artikelpass;
}

export interface ArtikelinformationData {
  Informationsart: Informationsart;
  Information: string;
}

export async function artikelpassLaden(request: Request): Promise<Response> {
  return client.request('art', 'ArtikelpassLaden', request)
    .then(response => response.data);
}
