import client from '@/api/client';

export enum Einheitsverwendung {
  None = 0,
  Umrechnungseinheit = 1 << 0,
  Schwellwerteinheit = 1 << 1,
  Zeiteinheit = 1 << 2,
  Dispogroesse = 1 << 3,
  Anliefereinheit = 1 << 4,
  Grundpreisbasis = 1 << 5,
  Grundpreisinhalt = 1 << 6,
  Inhalt = 1 << 7,
  Packeinheit = 1 << 8,
  Verpackungsart = 1 << 9,
  Gebindeart = 1 << 10,
  RestlaufzeitEinheit = 1 << 11,
  AbmessungEinheit = 1 << 12,
  Vitamine = 1 << 13,
  Bestellmenge = 1 << 14,
  Bestellwert = 1 << 15,
  Faktor =  1 << 16,
}

export interface EinheitenLadenInput {
  Verwendung: Einheitsverwendung;
}

export interface EinheitenLadenOutputItem {
  Id: string;
  Bezeichnung: string;
  Einheitsart: number;
  Verwendung: number;
}

export async function einheitenLaden(
  request: EinheitenLadenInput,
): Promise<EinheitenLadenOutputItem[]> {
  return client.request('art', 'EinheitenLaden', request)
    .then(response => response.data.Einheitdatas);
}
