<template>
  <v-input
    v-model="internalValue"
    v-bind="attrs"
    @input="onInput"
    @blur="onBlur"
    v-on="listeners"
  />
</template>

<script>
export default {
  name: 'VInputNumber',

  inheritAttrs: false,

  props: {
    value: {
      type: Number,
      default: null,
    },

    decimalPlaces: {
      type: Number,
      default: 2,
    },

    min: {
      type: Number,
      default: Number.MIN_SAFE_INTEGER || -9007199254740991,
    },

    max: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER || 9007199254740991,
    },
  },

  data() {
    return {
      internalValue: '',
    };
  },

  computed: {
    listeners() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { input, blur, ...listeners } = this.$listeners;

      return listeners;
    },

    attrs() {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { value, ...attrs } = this.$attrs;

      return attrs;
    },

    internalValueNumber() {
      if (this.internalValue === '') {
        return null;
      }

      return this.parse(this.internalValue);
    },
  },

  watch: {
    value(newValue) {
      if (this.$el.contains(document.activeElement)) {
        return;
      }

      if (newValue === null) {
        this.internalValue = '';
      } else {
        this.internalValue = this.format(newValue);
      }
    },
  },

  created() {
    this.internalValue = this.value === null ? '' : this.format(this.value);
  },

  methods: {
    onInput() {
      if (this.internalValueNumber === null) {
        this.$emit('input', null);

        return;
      }

      let number = Number.parseFloat(this.internalValueNumber.toFixed(this.decimalPlaces));

      if (number < this.min) {
        number = this.min;
      }

      if (number > this.max) {
        number = this.max;
      }

      this.$emit('input', number);
    },

    onBlur(event) {
      this.internalValue = this.value !== null ? this.format(this.value) : '';

      this.$emit('blur', event);
    },

    format(value) {
      return this.$n(value, {
        useGrouping: false,
        minimumFractionDigits: this.decimalPlaces,
        maximumFractionDigits: this.decimalPlaces,
      });
    },

    parse(value) {
      const decimalSeperator = this.getDecimalSeparator();
      const regex = new RegExp('[^0-9-' + decimalSeperator + ']', 'g');

      const number = Number.parseFloat(value.replace(regex, '').replace(decimalSeperator, '.'));

      return !isNaN(number) ? number : null;
    },

    getDecimalSeparator() {
      if (this.decimalSeperator) {
        return this.decimalSeperator;
      }

      const part = Intl.NumberFormat(this.$i18n.locale)
        .formatToParts(1.1)
        .find(part => part.type === 'decimal');

      return part ? part.value : '.';
    },
  },
};
</script>
