
import Vue, { PropType } from 'vue';
import { integer } from 'vuelidate/lib/validators';

interface Value {
  height: number|null;
  width: number|null;
  depth: number|null;
}

export default Vue.extend({
  name: 'VInputDimension',

  props: {
    required: {
      type: Boolean,
      default: false,
    },

    value: {
      type: Object as PropType<Value>,
      required: false,
      default: () => {
        return {
          height: null,
          width: null,
          depth: null,
        };
      },
    },
  },

  data() {
    return {
      dimensions: this.value,
    };
  },

  computed: {
    allRequired(): boolean {
      return this.required ||
        !this.isEmpty(this.value.height) ||
        !this.isEmpty(this.value.width) ||
        !this.isEmpty(this.value.depth);
    },
  },

  validations: {
    dimensions: {
      depth: {
        integer,
      },
      width: {
        integer,
      },
      height: {
        integer,
      },
    },
  },

  watch: {
    dimensions: {
      handler(value: object): void {
        this.$emit('input', value);
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    isEmpty(value: number|null) {
      return value === null;
    },
  },
});
