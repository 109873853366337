import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import { isRouteAllowed } from '@/utils/route';

import AppShell from '@/components/app/AppShell.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: AppShell,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        redirect: { name: 'article-list' },
      },
      {
        path: '/articles',
        name: 'article-list',
        component: () => import('@/views/article/ArticleListBootstrap.vue'),
        meta: {
          permissions: ['wwsportal.artikel.uebersicht'],
        },
      },
      {
        path: '/articlesnew',
        name: 'article-list-new',
        component: () => import('@/views/article/ArticleList.vue'),
        meta: {
          permissions: ['wwsportal.artikel.uebersicht'],
        },
      },
      {
        path: '/articles/create',
        name: 'article-create',
        component: () => import('@/views/article/ArticleCreate.vue'),
        meta: {
          permissions: ['wwsportal.artikel.anlegen'],
        },
      },
      {
        path: '/articles/prices/edit',
        name: 'price-list-edit',
        component: () => import('@/views/article/PriceListEdit.vue'),
        meta: {
          permissions: [
            'wwsportal.einkaufspreise.aendern',
            'wwsportal.verkaufspreise.aendern',
          ],
        },
      },
      {
        path: '/price-changes',
        name: 'price-change-list',
        component: () => import('@/views/price/PriceChangeList.vue'),
        meta: {
          permissions: ['wwsportal.preisaenderungen.uebersicht'],
        },
      },
      {
        path: '/price-changes/edit',
        name: 'price-change-list-edit',
        component: () => import('@/views/price/PriceChangeListEdit.vue'),
        meta: {
          permissions: ['wwsportal.preisaenderungen.bearbeiten'],
        },
      },
      {
        path: '/articles/listing-request',
        name: 'article-listing-request',
        component: () => import('@/views/article/ListingRequest.vue'),
        meta: {
          permission: [],
        },
      },
      {
        path: '/articles/:id([^/]+)',
        component: () => import('@/views/article/ArticleDetail.vue'),
        props: true,
        meta: {
          permissions: ['wwsportal.artikel.anzeigen'],
        },
        children: [
          {
            path: '',
            name: 'article-detail-basic',
            component: () => import('@/views/article/ArticleDetailBasic.vue'),
          },
          {
            path: 'identifiers',
            name: 'article-detail-identifiers',
            component: () => import('@/views/article/ArticleDetailIdentifiers.vue'),
          },
          {
            path: 'infos',
            name: 'article-detail-infos',
            component: () => import('@/views/article/ArticleDetailInfos.vue'),
          },
          {
            path: 'prices',
            name: 'article-detail-prices',
            component: () => import('@/views/article/ArticleDetailPrices.vue'),
          },
          {
            path: 'attributes',
            name: 'article-detail-attributes',
            component: () => import('@/views/article/ArticleDetailAttributes.vue'),
          },
          {
            path: 'packages',
            name: 'article-detail-packages',
            component: () => import('@/views/article/ArticleDetailPackages.vue'),
          },
          {
            path: 'components',
            name: 'article-detail-components',
            component: () => import('@/views/article/ArticleDetailComponents.vue'),
          },
          {
            path: 'compounds',
            name: 'article-detail-compounds',
            component: () => import('@/views/article/ArticleDetailCompounds.vue'),
          },
        ],
      },
      {
        path: '/articles/:id([^/]+)/edit',
        component: () => import('@/views/article/ArticleEdit.vue'),
        props: true,
        meta: {
          permissions: ['wwsportal.artikel.aendern'],
        },
        children: [
          {
            path: '',
            name: 'article-edit-basic',
            component: () => import('@/views/article/ArticleEditBasic.vue'),
          },
          {
            path: 'infos',
            name: 'article-edit-infos',
            component: () => import('@/views/article/ArticleEditInfos.vue'),
          },
          {
            path: 'attributes',
            name: 'article-edit-attributes',
            component: () => import('@/views/article/ArticleEditAttributes.vue'),
          },
          {
            path: 'components',
            name: 'article-edit-components',
            component: () => import('@/views/article/ArticleEditComponents.vue'),
          },
          {
            path: 'compound-prices',
            name: 'article-edit-compound-prices',
            component: () => import('@/views/article/ArticleEditCompoundPrices.vue'),
          },
        ],
      },
      {
        path: '/suppliers',
        name: 'supplier-list',
        component: () => import('@/views/supplier/SupplierList.vue'),
        meta: {
          permissions: ['wwsportal.lieferant.uebersicht'],
        },
      },
      {
        path: '/suppliers/create',
        name: 'supplier-create',
        component: () => import('@/views/supplier/SupplierCreate.vue'),
        meta: {
          permissions: ['wwsportal.lieferant.anlegen'],
        },
      },
      {
        path: '/suppliers/:id([^/]+)/edit',
        name: 'supplier-edit',
        component: () => import('@/views/supplier/SupplierEdit.vue'),
        props: true,
        meta: {
          permissions: ['wwsportal.lieferant.aendern'],
        },
      },
      {
        path: '/companies/:id([^/]+)/edit',
        name: 'company-edit',
        component: () => import('@/views/supplier/CompanyEdit.vue'),
        props: true,
        meta: {
          permissions: ['wwsportal.unternehmen.aendern'],
        },
      },
      {
        path: '/suppliers/:id([^/]+)',
        component: () => import('@/views/supplier/SupplierDetail.vue'),
        props: true,
        meta: {
          permissions: ['wwsportal.lieferant.anzeigen'],
        },
        children: [
          {
            name: 'supplier-detail-company',
            path: 'company',
            component: () => import('@/views/supplier/SupplierDetailCompany.vue'),
          },
          {
            name: 'supplier-detail-supplier',
            path: 'supplier',
            component: () => import('@/views/supplier/SupplierDetailSupplier.vue'),
          },
        ],
      },
      {
        path: '/promotions',
        name: 'promotion-list',
        component: () => import('@/views/promotion/PromotionList.vue'),
      },
      {
        path: '/promotions/create',
        name: 'promotion-create',
        component: () => import('@/views/promotion/PromotionCreate.vue'),
      },
      {
        path: '/promotions/:d',
        name: 'promotion-detail',
        component: () => import('@/views/promotion/PromotionDetail.vue'),
      },
      {
        path: '/admin/users',
        name: 'user-list',
        component: () => import('@/views/user/UserList.vue'),
        meta: {
          permissions: ['wwsportal.benutzer.uebersicht'],
        },
        props: {
          admin: true,
        },
      },

      {
        path: '/store-users',
        name: 'user-store-list',
        component: () => import('@/views/user/UserList.vue'),
        meta: {
          permissions: ['wwsportal.benutzer.markt.uebersicht'],
        },
        props: {
          admin: false,
        },
      },
      {
        path: '/listing-requests',
        name: 'listing-request-list',
        component: () => import('@/views/listing-request/ListingRequestList.vue'),
        meta: {
          permissions: ['wwsportal.listungsanforderung.uebersicht'],
        },
      },
      {
        path: '/forbidden',
        name: 'forbidden',
        component: () => import('@/views/Forbidden.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/components/app/Login.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/views/Register.vue'),
      },
      {
        path: '/password-reset/request',
        name: 'password-reset-request',
        props: true,
        component: () => import('@/views/PasswordResetRequest.vue'),
      },
      {
        path: '/password-reset/:token',
        name: 'password-reset',
        props: true,
        component: () => import('@/views/PasswordReset.vue'),
      },
    ],
  },
  {
    path: '/about/legal-notice',
    name: 'about-legal-notice',
    component: () => import('@/views/about/LegalNotice.vue'),
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/Maintenance.vue'),
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('@/views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.user === null) {
      next('/login');
    } else {
      if (isRouteAllowed(to)) {
        next();
      } else {
        next('/forbidden');
      }
    }
  } else {
    next();
  }
});

export default router;
