
import Vue from 'vue';
import { BNavbar } from 'bootstrap-vue';

export default Vue.extend({
  name: 'VNavbar',

  components: {
    BNavbar,
  },

  inheritAttrs: false,
});
