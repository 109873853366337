import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  LieferantId: UUID;
  LieferantRowVersion: number;
  KeinEtikett: boolean;
}

export async function lieferantenAendern(request: Request): Promise<null> {
  return client.request('lua', 'LieferantenAendern', request)
    .then(response => response.data);
}
