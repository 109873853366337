import client from '@/api/client';

export interface Request {
  Gln: string;
}

export async function glnValidieren(request: Request): Promise<null> {
  return client.request('org', 'GlnValidieren', request)
    .then(response => response.data);
}
