import { loadMessages } from '@progress/kendo-vue-intl';

const language = 'de';

const messages = {
  grid: {
    groupPanelEmpty: 'Ziehen Sie einen Tabellenkopf hierher, um die Tabelle nach der Spalte zu gruppieren.',
    noRecords: 'Keine Einträge vorhanden.',
    pagerFirstPage: 'Zur ersten Seite gehen',
    pagerPreviousPage: 'Zur vorherigen Seite gehen',
    pagerNextPage: 'Zur nächsten Seite gehen',
    pagerLastPage: 'Zur letzten Seite gehen',
    pagerPage: 'Seite',
    pagerOf: 'von',
    pagerItems: 'Einträgen',
    pagerInfo: '{0} - {1} von {2} Einträgen',
    pagerItemsPerPage: 'Einträge pro Seite',
    filterEqOperator: 'Ist gleich',
    filterNotEqOperator: 'Ist ungleich',
    filterIsNullOperator: 'Ist null',
    filterIsNotNullOperator: 'Ist nicht null',
    filterIsEmptyOperator: 'Ist leer',
    filterIsNotEmptyOperator: 'Ist nicht leer',
    filterStartsWithOperator: 'Beginnt mit',
    filterContainsOperator: 'Enthält',
    filterNotContainsOperator: 'Enthält nicht',
    filterEndsWithOperator: 'Endet mit',
    filterGteOperator: 'Ist größer oder gleich',
    filterGtOperator: 'Ist größer',
    filterLteOperator: 'Ist kleiner oder gleich',
    filterLtOperator: 'Ist kleiner',
    filterIsTrue: 'Ja',
    filterIsFalse: 'Nein',
    filterBooleanAll: '(Alle)',
    filterAfterOrEqualOperator: 'Ist hinter oder gleich zu',
    filterAfterOperator: 'Ist hinter',
    filterBeforeOperator: 'Ist bevor',
    filterBeforeOrEqualOperator: 'Ist bevor oder gleich',
    filterFilterButton: 'Filter',
    filterClearButton: 'Filter entfernen',
    filterAndLogic: 'und',
    filterOrLogic: 'oder',
  },
};

loadMessages(messages, language);
