import { Module } from 'vuex';
import { State as RootState } from '.';

interface Article {
  id: unknown;
  name: string;
  setNumber: string;
  contents: string;
}

interface State {
  articles: Article[];
  listingType: ListingType;
}

export enum ListingType{
  list,
  delist
}

const storeModule: Module<State, RootState> = {
  namespaced: true,

  state: {
    articles: [],
    listingType: ListingType.list,
  },

  mutations: {
    setArticles(state, articles) {
      state.articles = articles;
    },

    removeArticle(state, articleId) {
      state.articles = state.articles.filter((article) => article.id !== articleId);
    },

    setListingType(state, listingType) {
      state.listingType = listingType;
    },
  },
};

export default storeModule;
