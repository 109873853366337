<template>
  <div
    class="page"
    :class="{ 'page-full-width': fullWidth }"
  >
    <template v-for="serviceInformation in serviceInformations">
      <div
        :key="serviceInformation.Id" 
        :class="getPageNoteClass(serviceInformation)"
      >
        <div
          v-if="serviceInformation.Titel"
          class="text-center font-weight-bold"
        >
          {{ serviceInformation.Titel }} 
        </div>
        <div class="text-center">
          {{ serviceInformation.Nachricht }}
        </div>
      </div>
    </template>
    <slot />
  </div>
</template>

<script>
import { ladeServiceinfos } from '@/api/bitwws/wws/ladeServiceinfos';

export default {
  name: 'VPage',

  provide() {
    const proxy = {};

    Object.defineProperty(proxy, 'scrollbarOffset', {
      get: () => this.fullWidth ? 0 : this.scrollbarOffset,
    });

    return {
      page: proxy,
    };
  },

  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },

    contentElement: {
      type: [String, HTMLElement],
      default: '.page-content',
    },
  },

  data() {
    return {
      scrollbarOffset: 0,
      serviceInformations: [],
    };
  },

  created() {
    this.observer = new MutationObserver(() => {
      this.setScrollbarOffset();
    });
    this.loadServiceInformations();
  },

  mounted() {
    this.observer.observe(this.$el, {
      subtree: true,
      childList: true,
    });

    this.setScrollbarOffset();
  },

  beforeDestroy() {
    this.observer.disconnect();
  },

  methods: {

    async loadServiceInformations() {
      return ladeServiceinfos()
        .then((serviceInformations) => {
          this.serviceInformations = serviceInformations.ServiceInformationDatas;
        });
    },

    getPageNoteClass(element){
      switch(element.Dringlichkeit){
      case 0: return 'page-note-info';
      case 1: return 'page-note-warning';
      case 2: return 'page-note-danger';
      default: return 'page-note-info';
      }
    },
    
    setScrollbarOffset() {
      let contentElement = null;

      if (this.contentElement instanceof HTMLElement) {
        contentElement = this.contentElement;
      } else {
        contentElement = document.querySelector(this.contentElement);
      }

      if (contentElement instanceof HTMLElement) {
        this.scrollbarOffset = contentElement.offsetWidth - contentElement.clientWidth;
      }
    },

    removeSidebarOffset() {
      this.sidebarOffset = 0;
    },
  },
};
</script>
