<template>
  <b-badge
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </b-badge>
</template>

<script>
import { BBadge } from 'bootstrap-vue';

export default {
  name: 'VBadge',

  components: {
    BBadge,
  },

  inheritAttrs: false,
};
</script>
