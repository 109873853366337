<template>
  <b-modal
    ref="modal"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />

    <slot
      v-for="slot in Object.keys($slots)"
      :slot="slot"
      :name="slot"
    />

    <template
      v-for="slot in Object.keys($scopedSlots)"
      #[slot]="scope"
    >
      <slot
        v-bind="scope"
        :name="slot"
      />
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';

export default {
  name: 'VModal',

  components: {
    BModal,
  },

  inheritAttrs: false,

  model: {
    prop: 'visible',
    event: 'change',
  },

  methods: {
    scrollTop() {
      this.$refs.modal.$refs.body.scrollTop = 0;
    },
  },
};
</script>
