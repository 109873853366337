import client from '@/api/client';
import { UUID } from './types';
import { Informationsart } from './enums/Informationsart';

export interface Request {
  ArtikelId: UUID;
  KundeId: UUID;
  Stichtag: string;
  ArtikelRowVersion: number;
  ArtikelinformationDataList: ArtikelinformationData[];
}

export type Response = null;

export interface ArtikelinformationData {
  Informationsart: Informationsart;
  Information: string;
}

export async function artikelinformationenAendern(request: Request): Promise<Response> {
  return client.request('art', 'ArtikelinformationenAendern', request)
    .then(response => response.data);
}
