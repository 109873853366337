import client from '@/api/client';

export enum Sortimentsklasse {
  SortimentNeuesWWS = 0,
  SortimentAltesWWS = 1 << 0,
  Hauptplatzierung = 1 << 1,
}

export interface Request {
  FilialeId: string;
  SuchText: string;
  Sortimentsklasse: Sortimentsklasse;
  LieferantId: string | null;
}

export interface Response {
  Sortimentdatas: Sortiment[];
}

export interface Sortiment {
  Id: string;
  Level: number;
  BezeichnungUndNummer: string;
  Nummer: number;
  Pfad: string;
  Aktiv: boolean;
}

export async function sortimentSuchen(request: Request): Promise<Response> {
  return client.request('art', 'SortimentSuchen', request)
    .then(response => response.data);
}
