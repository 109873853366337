import store from '@/store';

export function hasPermissions(permissions: string[]): boolean {
  const { permissions: userPermissions } = store.state;

  return permissions.every((required: string) => {
    return userPermissions.includes(required);
  });
}

export function hasSomePermission(permissions: string[]): boolean {
  const { permissions: userPermissions } = store.state;

  return permissions.some((required: string) => {
    return userPermissions.includes(required);
  });
}
