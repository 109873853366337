import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  UnternehmensId: UUID;
  FilialId: UUID;
  KundenId: UUID;
  KeinEtikett: boolean;
  ZahlungszielTage: number;
  Skonto: number;
  SkontoTage: number;
  Hauptwarengruppe: number;
  SortimenteNeuesWws: number[];
  SortimenteAs400: number[];
}

export async function lieferantenAnlegen(request: Request): Promise<null> {
  return client.request('lua', 'LieferantenAnlegen', request)
    .then(response => response.data);
}
