<template>
  <section class="section">
    <header>
      <h1
        v-if="title"
      >
        {{ title }}
      </h1>

      <div
        v-if="hasActionsSlot"
        class="section-actions"
      >
        <slot name="actions" />
      </div>
    </header>

    <slot />
  </section>
</template>

<script>
import disabledParent from '@/mixins/disabled-parent';

export default {
  name: 'VFormSection',

  mixins: [disabledParent],

  props: {
    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    hasActionsSlot() {
      return this.$slots['actions'] !== undefined;
    },
  },
};
</script>
