
import { BButtonToolbar } from 'bootstrap-vue';

export default {
  name: 'VButtonToolbar',

  components: {
    BButtonToolbar,
  },

  inheritAttrs: false,
};
