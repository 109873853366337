import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  VerpackungId: string;
  VerpackungRowVersion: number;
  GueltigVon: string;
  GueltigBis: string;
  GtinDataList: GtinData[];
  KundeId: UUID;
}

export interface Response {
  GtinIds: UUID[];
}

export interface GtinData {
  Gtin: string;
  IstHauptGtin: boolean;
  HausinternVergeben: boolean;
}

export async function gtinsHinzufuegen(request: Request): Promise<Response> {
  return client.request('art', 'GtinsHinzufuegen', request)
    .then(response => response.data);
}
