import { IntlProvider } from '@progress/kendo-vue-intl';
import { getDefaultSlots } from '@progress/kendo-vue-common';

// Override provider to remove wrapper div element.
const CustomIntlProvider = {
  ...IntlProvider,
  render: function render() {
    const defaultSlot = getDefaultSlots(this);

    return defaultSlot;
  },
};

export { CustomIntlProvider as IntlProvider };
