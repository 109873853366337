import client from '@/api/client';
import { UUID } from './types';

export interface Request {
  FilialeId: UUID;
  ArtikelId: UUID;
  VerpackungId: UUID;
  VerkaufsverpackungId: UUID;
  Stichtag: string;
  ArtikelRowVersion: number;
  VerpackungRowVersion: number;
  VerkaufsverpackungRowVersion: number;
  Artikelbezeichnung: string;
  ArtikelbezeichnungGeaendert: boolean;
  VerpackungsartId: UUID;
  VerpackungsartGeaendert: boolean;
  InhaltFaktor: number;
  InhaltMenge: number;
  InhaltEinheitId: UUID;
  InhaltGeaendert: boolean;
  ArtDerVerkaufseinheit?: number;
  ArtDerVerkaufseinheitGeaendert?: boolean;
  UmsatzsteuerartId: UUID;
  UmsatzsteuerartGeaendert: boolean;
  WarennummerAussenhandelSchluessel?: null;
  HauptplatzierungId: UUID;
  SortimentId: UUID;
  KlassifizierungGeaendert: boolean;
  Regaltext: string;
  Bontext: string;
  RegalOderBontextGeaendert: boolean;
  PluKreis: number;
  NaechsteFreiePlu: boolean;
  NeuePlu: number | null;
  PluGeaendert: boolean;
  HausinterneGtinVergeben: boolean;
  Gtin: string;
  GtinGeaendert: boolean;
  LeergutId?: UUID;
  LeergutIdGeaendert?: boolean;
  PreiseingabeAnKasse: boolean;
  PreiseingabeAnKasseGeaendert: boolean;
  IstRabattfaehig: boolean;
  IstRabattfaehigGeaendert: boolean;
}

export async function artikelAendern(request: Request): Promise<null> {
  return client.request('art', 'HofArtikelAendern', request)
    .then(response => response.data);
}
