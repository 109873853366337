<template>
  <div
    class="dropdown-listbox"
    :class="{ 'open': opened }"
  >
    <button
      ref="button"
      aria-haspopup="listbox"
      :aria-label="label"
      :aria-expanded="opened"
      @click.prevent="toggle()"
      @keydown.down="open()"
    >
      <slot
        name="selected"
        :option="selected"
      >
        {{ selected }}
      </slot>
    </button>

    <ul
      ref="list"
      role="listbox"
      tabindex="-1"
      :aria-activedescendant="`${id}-option-${focusedIndex}`"
      @keydown.up.prevent="focusPreviousOption()"
      @keydown.down.prevent="focusNextOption()"
      @keydown.enter.prevent="selectOption(focusedIndex)"
      @keydown.esc.prevent="close()"
      @blur.prevent="blur"
    >
      <slot name="list-start" />
      <li
        v-for="(option, index) in options"
        :id="`${id}-option-${index}`"
        :key="index"
        role="option"
        :aria-label="label"
        :aria-selected="option === selected"
        :class="{ focus: index === focusedIndex, active: option === selected }"
        @click.prevent.stop="selectOption(index)"
      >
        <slot
          name="option"
          :option="option"
        >
          {{ option }}
        </slot>
      </li>
      <slot name="list-end" />
    </ul>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
import Popper from 'popper.js';

export default {
  name: 'VDropdownListbox',

  model: {
    prop: 'selected',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      default: () => uniqueId('v-dropdown-listbox-'),
    },

    selected: {
      type: null,
      default: null,
    },

    options: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      opened: false,
      label: 'Lieferant auswählen',
      focusedIndex: 0,
    };
  },

  created() {
    this.popper = null;
  },

  deactivated() {
    this.destroyPopper();
  },

  beforeDestroy() {
    document.removeEventListener('click', this.clickOutHandler);
  },

  mounted() {
    document.addEventListener('click', this.clickOutHandler);
  },

  methods: {
    selectOption(index) {
      const option = this.options[index];
      this.focusedIndex = index;

      this.$emit('change', option);
      this.close();
    },

    focusNextOption() {
      this.open();

      if (this.focusedIndex < this.options.length - 1) {
        this.focusOption(this.focusedIndex + 1);
      }
    },

    focusPreviousOption() {
      this.open();

      if (this.focusedIndex > 0) {
        this.focusOption(this.focusedIndex - 1);
      }
    },

    focusOption(index) {
      this.focusedIndex = index;
    },

    clickOutHandler(event) {
      if (this.opened && !this.$el.contains(event.target)) {
        this.close();
      }
    },

    toggle() {
      if (this.opened) {
        this.close();
      } else {
        this.open();
      }
    },

    open() {
      this.opened = true;
      this.createPopper(this.$el);

      this.$nextTick(() => {
        this.$refs.list.focus();
      });
    },

    close() {
      this.opened = false;

      this.$nextTick(() => {
        this.destroyPopper();
        this.$refs.button.focus();
      });
    },

    blur(event) {
      if (
        !this.opened
        || (event.relatedTarget && this.$refs.button.contains(event.relatedTarget))
      ) {
        return;
      }

      this.close();
    },

    createPopper(element) {
      this.destroyPopper();
      this.popper = new Popper(element, this.$refs.list, this.getPopperConfig());
    },

    destroyPopper() {
      if (this.popper) {
        this.popper.destroy();
      }
      this.popper = null;
    },

    getPopperConfig() {
      const popperConfig = {
        placement: 'bottom-start',
        modifiers: {
          offset: { offset: 0 },
          preventOverflow: { boundariesElement: 'scrollParent' },
        },
      };

      return popperConfig;
    },
  },
};
</script>
