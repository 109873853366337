
import Vue from 'vue';
import { BDropdown } from 'bootstrap-vue';

export default Vue.extend({
  name: 'VDropdown',

  components: {
    BDropdown,
  },

  inheritAttrs: false,
});
